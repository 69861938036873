import React from "react";

import { IconType } from "@lib/shared-types";

interface Props {
  Icon: IconType;
}

const CircledIcon: React.FC<Props> = ({ Icon }) => (
  <div className="flex shrink-0 w-16 h-16 rounded-full flex items-center justify-center bg-foreground/7 text-accent">
    <Icon className="w-8 h-8 absolute" />
  </div>
);

export default CircledIcon;
