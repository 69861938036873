import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const MarkAsReadIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8536 8.56066C16.0488 8.3654 16.0488 8.04882 15.8536 7.85355L15.1464 7.14645C14.9512 6.95118 14.6346 6.95118 14.4393 7.14645L10 11.5858L9.56066 11.1464C9.3654 10.9512 9.04882 10.9512 8.85355 11.1464L8.14645 11.8536C7.95118 12.0488 7.95118 12.3654 8.14645 12.5607L8.58579 13L7.5 14.0858L4.56066 11.1464C4.3654 10.9512 4.04882 10.9512 3.85355 11.1464L3.14645 11.8536C2.95118 12.0488 2.95118 12.3654 3.14645 12.5607L7.14645 16.5607C7.34171 16.7559 7.65829 16.7559 7.85355 16.5607L10 14.4142L12.1464 16.5607C12.3417 16.7559 12.6583 16.7559 12.8536 16.5607L20.8536 8.56066C21.0488 8.3654 21.0488 8.04882 20.8536 7.85355L20.1464 7.14645C19.9512 6.95118 19.6346 6.95118 19.4393 7.14645L12.5 14.0858L11.4142 13L15.8536 8.56066Z"
      />
    </svg>
  );
};

export default MarkAsReadIcon;
