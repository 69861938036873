import React from "react";
import classNames from "classnames";
import Link from "next/link";

export interface MenuButtonProps {
  className?: string;
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  href?: string;
  target?: "_blank";
  children?: React.ReactNode;
  heapEvent?: string;
  disabled?: boolean;
}

const MenuButton: React.FC<MenuButtonProps> = ({
  className,
  icon,
  onClick,
  href,
  target,
  children,
  heapEvent = "",
  disabled = false,
}) => {
  const content = (
    <button
      className={classNames(
        className,
        "flex justify-between items-center w-full text-left px-4 py-2 leading-5 text-black-ink hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:cursor-not-allowed"
      )}
      onClick={onClick}
      data-heap-event-name={heapEvent}
      disabled={disabled}
    >
      {children} {icon}
    </button>
  );

  return href && target ? (
    <a href={href} target={target} data-heap-event-name={heapEvent}>
      {content}
    </a>
  ) : href && !target ? (
    <Link href={href} data-heap-event-name={heapEvent} legacyBehavior>
      {content}
    </Link>
  ) : (
    <>{content}</>
  );
};

export default MenuButton;
