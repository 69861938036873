import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ArrowShortcutIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8587 7.53772C16.1348 7.53772 16.3587 7.76158 16.3587 8.03772V14.0377C16.3587 14.3139 16.1348 14.5377 15.8587 14.5377H14.8587C14.5825 14.5377 14.3587 14.3139 14.3587 14.0377V11.0555L8.41411 17H12.4999C12.776 17 12.9999 17.2239 12.9999 17.5V18.5C12.9999 18.7762 12.776 19 12.4999 19H6.33399C6.13176 19 5.94944 18.8782 5.87205 18.6914L5.42064 17.6016C5.34325 17.4147 5.38603 17.1997 5.52903 17.0567L10.4695 12.1162C11.1798 11.3397 12.0047 10.4775 12.9444 9.53772H9.85865C9.58251 9.53772 9.35865 9.31386 9.35865 9.03772L9.35865 8.03772C9.35865 7.76158 9.58251 7.53772 9.85865 7.53772H15.8587Z"
      />
    </svg>
  );
};

export default ArrowShortcutIcon;
