import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const NotificationIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.126 6C13.5701 4.27477 15.1362 3 17 3C19.2091 3 21 4.79086 21 7C21 8.86384 19.7252 10.4299 18 10.874V19C18 19.5523 17.5523 20 17 20H5C4.44772 20 4 19.5523 4 19V7C4 6.44772 4.44772 6 5 6H13.126ZM19 7C19 8.10457 18.1046 9 17 9C15.8954 9 15 8.10457 15 7C15 5.89543 15.8954 5 17 5C18.1046 5 19 5.89543 19 7ZM16 10.874C14.5944 10.5122 13.4878 9.40561 13.126 8H6V18H16V10.874Z"
      />
    </svg>
  );
};

export default NotificationIcon;
