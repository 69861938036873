import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

// To match design library, this should really be named HeartIcon while current
// HeartIcon should be HeartIllustration in an illustrations folder.
const HeartOutlineIcon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0623 5.96831C6.88393 5.36203 7.90118 5 9 5C10.1337 5 11.1696 5.38145 12 6.0154C12.8304 5.38145 13.8663 5 15 5C16.0988 5 17.116 5.36202 17.9377 5.96828C19.2005 6.85125 20 8.31428 20 10.0769C20 12.9319 17.9785 15.1778 16.4449 16.497C15.6198 17.2068 14.7869 17.7762 14.1258 18.1735C13.7956 18.3719 13.491 18.5377 13.2355 18.6611C13.1104 18.7216 12.9743 18.7829 12.8394 18.8342C12.773 18.8594 12.6828 18.8914 12.5795 18.9197C12.5128 18.938 12.2867 19 12 19C11.7133 19 11.4872 18.938 11.4205 18.9197C11.3172 18.8914 11.227 18.8594 11.1606 18.8342C11.0257 18.7829 10.8896 18.7216 10.7645 18.6611C10.509 18.5377 10.2044 18.3719 9.87419 18.1735C9.2131 17.7762 8.38023 17.2068 7.55511 16.497C6.02155 15.1778 4 12.9319 4 10.0769C4 8.31429 4.79949 6.85128 6.0623 5.96831ZM7.2292 7.59295C7.7255 7.22013 8.33768 7 9 7C9.62114 7 10.1982 7.19361 10.6769 7.52521C10.9081 7.68531 11.1163 7.87758 11.2955 8.09576C11.3556 8.16894 11.4124 8.24504 11.4658 8.32382C11.5517 8.45079 11.6286 8.58471 11.6955 8.72461C11.8069 8.90342 11.909 9.09766 12 9.3077C12.091 9.09766 12.1931 8.90342 12.3045 8.7246C12.3714 8.58471 12.4483 8.45079 12.5342 8.32383C12.5876 8.24505 12.6444 8.16895 12.7045 8.09577C12.8837 7.87759 13.0919 7.68531 13.3231 7.52521C13.8018 7.19361 14.3789 7 15 7C15.6623 7 16.2745 7.22012 16.7708 7.59293C17.4968 8.09085 18 8.94038 18 10.0769C18 13.9231 12.375 17 12 17C11.625 17 6 13.9231 6 10.0769C6 8.94039 6.50322 8.09087 7.2292 7.59295Z"
    />
  </svg>
);

export default HeartOutlineIcon;
