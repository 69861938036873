import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const AlarmOnIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 19H14V20C14 20.5523 13.5523 21 13 21H11C10.4477 21 10 20.5523 10 20V19Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4.25204C17.4505 5.14012 20 8.27232 20 12V17C20 17.5523 19.5523 18 19 18H5C4.44772 18 4 17.5523 4 17V12C4 8.27232 6.54955 5.14012 10 4.25204V4C10 3.44772 10.4477 3 11 3H13C13.5523 3 14 3.44772 14 4V4.25204ZM18 12V16H6V12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12Z"
      />
    </svg>
  );
};

export default AlarmOnIcon;
