import { FC, ReactNode } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";

import { useCustomOrDarkTheme } from "@contexts/theme";
import useIsMobileWebView from "@hooks/use-is-mobile-webview";
import { SanitizedUserType } from "@lib/shared-types";

import { ClientHeaderButtonType } from "@components/Client/ClientHeaderButtons";
import ClientPageHeader from "@components/Client/ClientPageHeader";
import TypeIllustration from "@components/TypeIllustration";

export interface ClientContainerLayoutProps {
  icon?: string;
  title?: string;
  coach?: SanitizedUserType;
  isLoading?: boolean;
  children: ReactNode;
  beforeTitle?: ReactNode;
  headerActions?: ClientHeaderButtonType[];
  iconFileType?: "svg" | "png" | "jpg" | "jpeg";
  onlyThemedIfHasWhiteLabel?: boolean;
  hideLoginButton?: boolean;
}

const ClientContainerLayout: FC<ClientContainerLayoutProps> = ({
  icon,
  title,
  coach,
  isLoading,
  children,
  beforeTitle,
  headerActions,
  iconFileType = "svg",
  onlyThemedIfHasWhiteLabel = false,
  hideLoginButton = false,
}) => {
  useCustomOrDarkTheme(coach, onlyThemedIfHasWhiteLabel);
  const router = useRouter();
  const mobileSession = useIsMobileWebView();
  const { iframe } = router.query;
  const isEmbedded = !!iframe;
  const renderHeader = coach && !iframe && !mobileSession;
  const hasTitle = !isEmbedded || iframe.includes("title");
  // Dont render layout on serverside if embedded view (causes blinking)
  const hideContent = isEmbedded && typeof window === "undefined";

  return (
    <div className="flex-1 bg-background">
      {!hideContent && (
        <div
          className={classNames(
            "mx-auto px-8 max-w-screen-md flex flex-col items-center justify-start",
            (isEmbedded || !renderHeader) && "mt-8"
          )}
        >
          {renderHeader && (
            <ClientPageHeader
              coach={coach}
              headerActions={headerActions}
              onlyThemedIfHasWhiteLabel={onlyThemedIfHasWhiteLabel}
              hideLoginButton={hideLoginButton}
            />
          )}
          {beforeTitle}
          {hasTitle && !!title && (
            <div
              className={classNames(
                "text-center",
                isEmbedded ? "my-4" : "mt-12 mb-6"
              )}
            >
              <h1
                className={`text-2xl font-medium leading-tight flex flex-col items-center sm:flex-row sm:items-start ${
                  isLoading ? "text-accent/10" : "text-foreground"
                }`}
                id="client-container-title"
              >
                {isLoading ? (
                  <div className="min-h-screen">
                    &quot;Almost there...&quot;
                  </div>
                ) : (
                  <div className="flex flex-col items-center sm:flex-row">
                    {icon && (
                      <TypeIllustration
                        slug={icon}
                        fileType={iconFileType}
                        width={42}
                        height={42}
                        className={classNames(
                          isLoading && "hidden",
                          "sm:mr-4 mb-2"
                        )}
                      />
                    )}
                    <div className="sm:mb-1">{title}</div>
                  </div>
                )}
              </h1>
            </div>
          )}
          {children}
        </div>
      )}
    </div>
  );
};

export default ClientContainerLayout;
