import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const AlarmOffIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.70711 17.2929C3.31658 17.6834 3.31658 18.3166 3.70711 18.7071C4.09763 19.0976 4.7308 19.0976 5.12132 18.7071L5.82843 18H5.83602L7.83602 16H7.82843L16.156 7.67242L16.1599 7.67614L17.5743 6.26173L17.5704 6.25799L19.2635 4.56496C19.654 4.17443 19.654 3.54127 19.2635 3.15074C18.8729 2.76022 18.2398 2.76022 17.8492 3.15074L3.70711 17.2929Z" />
      <path d="M12.3015 5.87763C12.2218 5.95735 12.1127 5.99998 12 5.99998C8.75573 5.99998 6.11286 8.57487 6.00352 11.7925C5.99902 11.925 5.94732 12.0527 5.85355 12.1464C5.85355 12.1464 5.33333 12.6666 5 13C4.77614 13.2238 4.42678 13.5732 4.42678 13.5732C4.26929 13.7307 4 13.6191 4 13.3964V12C4 8.27231 6.54955 5.14011 10 4.25202V3.99998C10 3.4477 10.4477 2.99998 11 2.99998H13C13.5523 2.99998 14 3.4477 14 3.99998C14 4.1147 13.9544 4.22472 13.8733 4.30584L12.3015 5.87763Z" />
      <path d="M9.09123 17.5732C8.93374 17.7307 9.04528 18 9.26801 18H19C19.5523 18 20 17.5523 20 17V12C20 10.7003 19.6901 9.47302 19.1401 8.388C18.9938 8.09937 18.6106 8.05382 18.3818 8.28263L17.6169 9.0475C17.4643 9.2001 17.4288 9.43258 17.5141 9.63082C17.8268 10.3576 18 11.1586 18 12V16H11.0787C10.8134 16 10.5591 16.1053 10.3716 16.2929L9.09123 17.5732Z" />
      <path d="M14 19V20C14 20.5523 13.5523 21 13 21H11C10.4477 21 10 20.5523 10 20V19H14Z" />
    </svg>
  );
};

export default AlarmOffIcon;
