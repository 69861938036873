import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="fill-current"
        d="M6.136 14.794l-.44 1.644 1.68-.45.665.368a6.148 6.148 0 004.112.653c.445-.085.902.226 1.077.644.19.455.036 1.038-.445 1.147a8.026 8.026 0 01-5.656-.797l-2.521.675a.941.941 0 01-1.153-1.153l.657-2.453a8 8 0 0112.325-9.94c.343.318.028.866-.439.866-.36 0-.72.03-1.075.089a.548.548 0 01-.405-.093 6.118 6.118 0 00-8.786 8.118l.404.682z"
      ></path>
      <path
        fill="fill-current"
        d="M14.515 10.613c-.316.375-.515.942-.515 1.637a1 1 0 11-2 0c0-1.055.3-2.113.985-2.925C13.69 8.489 14.726 8 16 8c2.67 0 4.097 2.366 3.73 4.196-.305 1.527-1.19 2.236-1.807 2.73l-.103.084c-.312.252-.491.417-.614.61-.106.17-.206.42-.206.88a1 1 0 11-2 0c0-.79.182-1.42.515-1.947.316-.5.733-.845 1.048-1.099l.067-.055c.596-.48.978-.788 1.14-1.595.133-.67-.44-1.804-1.77-1.804-.726 0-1.188.26-1.485.613zM15 20a1 1 0 112 0 1 1 0 01-2 0z"
      ></path>
    </svg>
  );
};

export default Icon;
