import { FC, ReactNode } from "react";
import classNames from "classnames";

import { PaymentSummaryStyle } from "./PaymentSummary";

interface PaymentSummaryPaymentItemProps {
  title: string;
  subtitle: string;
  icon: JSX.Element;
  value: ReactNode;
  isDueAtBooking: boolean;
  style: PaymentSummaryStyle;
}

const PaymentSummaryPaymentItem: FC<PaymentSummaryPaymentItemProps> = ({
  title,
  subtitle,
  icon,
  value,
  isDueAtBooking,
  style,
}) => {
  const iconBackgroundColor = (): string => {
    if (isDueAtBooking) {
      return style == PaymentSummaryStyle.Translucent
        ? "bg-white"
        : "bg-action-900";
    } else {
      return "p-1 bg-grey-900";
    }
  };

  const titleTextColor = (): string => {
    if (style == PaymentSummaryStyle.Translucent) {
      return "text-white";
    } else {
      return isDueAtBooking ? "text-action-500" : "text-grey-500";
    }
  };

  return (
    <>
      {!isDueAtBooking && (
        <div className="border-grey-900 border-l-2 h-4 ml-2.5"></div>
      )}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div
            className={classNames(
              iconBackgroundColor(),
              "rounded-full w-min flex-none"
            )}
          >
            {icon}
          </div>
          <div>
            <h3 className={classNames(titleTextColor(), "text-sm font-medium")}>
              {title}
            </h3>
            <p
              className={classNames(
                "text-xs",
                style == PaymentSummaryStyle.Translucent
                  ? "text-action-500"
                  : "text-grey-500"
              )}
            >
              {subtitle}
            </p>
          </div>
        </div>

        <div className="text-right">
          <div className="font-medium text-sm text-grey-500">{value}</div>
        </div>
      </div>
    </>
  );
};

export default PaymentSummaryPaymentItem;
