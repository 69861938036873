import { useMemo } from "react";
import { NotificationMessage, NotificationStatus } from "@practice/sdk";
import classNames from "classnames";
import moment from "moment";
import Image from "next/image";

import {
  getNotificationIcon,
  getNotificationMessageParts,
} from "@lib/utils/notifications";

interface NotificationPanelListItemProps {
  notification: NotificationMessage;
  onClick?: () => void;
  onClickRead: () => void;
  className?: string;
}

const readButtonStyles = {
  unread:
    "bg-peach-600 border border-peach-600 group-hover/button:bg-peach-500 group-hover/button:border-peach-500 group-active/button:bg-peach-600 group-active/button:border-peach-600",
  read: "hidden group-hover/item:block group-focus/button:block bg-transparent group-hover/button:bg-grey-900 group-active/button:bg-transparent border border-grey-800",
};

const NotificationPanelListItem: React.FC<NotificationPanelListItemProps> = ({
  notification,
  onClick,
  onClickRead,
  className,
}) => {
  const isUnread = notification.status === NotificationStatus.Unread;
  const messageParts = useMemo(
    () => getNotificationMessageParts(notification),
    [notification]
  );

  const renderIcon = () => {
    const icon = getNotificationIcon(notification);
    const iconClassName = "w-6 h-6";

    if (typeof icon === "string") {
      return (
        <Image
          className={classNames(iconClassName, "rounded-full")}
          src={icon}
          width={24}
          height={24}
          alt="Notification icon"
        />
      );
    }

    const Icon = icon;

    return <Icon className={iconClassName} />;
  };

  const renderMessage = () => (
    <p className="text-sm truncate">
      <span className="text-black-ink font-medium">{messageParts[0]}</span>{" "}
      {messageParts.length > 1 && (
        <p className="text-grey-500">{messageParts.slice(1).join(" ")}</p>
      )}
    </p>
  );

  const renderReadButton = () => (
    <button
      className="absolute top-0 bottom-0 right-0 px-4 group/button"
      onClick={onClickRead}
    >
      <div
        className={classNames(
          "w-2 h-2 rounded-full",
          readButtonStyles[isUnread ? "unread" : "read"]
        )}
      ></div>
    </button>
  );

  return (
    <div
      className={classNames(
        "relative group/item",
        isUnread && "bg-white/40",
        className
      )}
    >
      <div
        className={classNames(
          "flex px-4 py-2 mr-4 gap-3 items-center",
          onClick && "cursor-pointer"
        )}
        tabIndex={onClick ? 0 : undefined}
        onClick={onClick}
      >
        <div className="flex-none">{renderIcon()}</div>
        <div className="flex-1 flex flex-col truncate">
          {renderMessage()}
          <p className="text-xs text-grey-500 truncate">
            {moment(notification.createdAt).fromNow()}
          </p>
        </div>
      </div>
      {renderReadButton()}
    </div>
  );
};

export default NotificationPanelListItem;
