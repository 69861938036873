import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const GearIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8087 4.10134C13.4092 2.21923 10.5908 2.21923 9.19135 4.10134L8.59203 4.90735C8.34526 5.23923 7.9724 5.4545 7.5616 5.50228L6.56391 5.6183C4.23422 5.88922 2.82504 8.32999 3.75526 10.483L4.15363 11.4051C4.31766 11.7847 4.31766 12.2152 4.15363 12.5949L3.75526 13.5169C2.82504 15.67 4.23422 18.1107 6.56391 18.3817L7.5616 18.4977C7.9724 18.5455 8.34526 18.7607 8.59203 19.0926L9.19135 19.8986C10.5908 21.7807 13.4092 21.7807 14.8087 19.8986L15.408 19.0926C15.6548 18.7607 16.0276 18.5455 16.4384 18.4977L17.4361 18.3817C19.7658 18.1107 21.175 15.67 20.2448 13.5169L19.8464 12.5949C19.6824 12.2152 19.6824 11.7847 19.8464 11.4051L20.2448 10.483C21.175 8.32999 19.7658 5.88922 17.4361 5.6183L16.4384 5.50228C16.0276 5.4545 15.6548 5.23924 15.408 4.90735L14.8087 4.10134ZM10.7963 5.29472C11.3961 4.4881 12.6039 4.4881 13.2037 5.29472L13.803 6.10073C14.3788 6.87513 15.2488 7.37742 16.2074 7.48889L17.2051 7.60491C18.2035 7.72102 18.8074 8.76706 18.4088 9.68979L18.0104 10.6118C17.6277 11.4977 17.6277 12.5023 18.0104 13.3881L18.4088 14.3102C18.8074 15.2329 18.2035 16.2789 17.2051 16.395L16.2074 16.5111C15.2488 16.6225 14.3788 17.1248 13.803 17.8992L13.2037 18.7052C12.6039 19.5119 11.3961 19.5119 10.7963 18.7052L10.197 17.8992C9.62117 17.1248 8.75117 16.6225 7.79262 16.5111L6.79494 16.395C5.7965 16.2789 5.19256 15.2329 5.59123 14.3102L5.98959 13.3881C6.37233 12.5023 6.37233 11.4977 5.98959 10.6118L5.59123 9.68979C5.19256 8.76706 5.7965 7.72102 6.79494 7.60491L7.79262 7.48889C8.75117 7.37742 9.62117 6.87513 10.197 6.10073L10.7963 5.29472Z"
      />
    </svg>
  );
};

export default GearIcon;
