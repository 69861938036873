import { useEffect } from "react";
import Image from "next/legacy/image";
import { NextRouter, useRouter } from "next/router";

import { SanitizedUserType } from "@lib/shared-types";

import AuthPageShell from "./Login/AuthPageShell";

type ThemedSpinnerProps = {
  coach?: SanitizedUserType;
};

export const ThemedSpinner: React.FC<ThemedSpinnerProps> = ({ coach }) => {
  return (
    <AuthPageShell
      metaRobots="noindex"
      coach={coach}
      aboveTitle={
        <Image
          src={"/images/spinner_web_96px_transparent.gif"}
          width="40"
          height="40"
          alt="Loading..."
          className="mx-auto mt-9"
        />
      }
      title="Redirecting..."
    />
  );
};

type NextRouterPush = Pick<NextRouter, "push">;
type PushOptions = Parameters<NextRouterPush["push"]>;

type RedirectProps = {
  coach?: SanitizedUserType;
  to: PushOptions[0];
  as?: PushOptions[1];
  options?: PushOptions[2];
  children?: JSX.Element;
};

export const Redirect: React.FC<RedirectProps> = ({
  coach,
  to,
  as,
  options,
  children,
}): JSX.Element => {
  const router = useRouter();

  useEffect(() => {
    router.push(to, as, options);
  }, [as, options, router, to]);

  return <>{children ?? <ThemedSpinner coach={coach} />}</>;
};
