import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const DocTextIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 8.00003H7.99997V10H12V8.00003Z" />
      <path d="M16 12V14H7.99997V12H16Z" />
      <path d="M16 17V15H7.99997V17H16Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3C4 2.44772 4.44772 2 5 2H16C18.2091 2 20 3.79086 20 6V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V3ZM6 20V4H16C17.1046 4 18 4.89543 18 6V20H6Z"
      />
    </svg>
  );
};

export default DocTextIcon;
