import { FC, useState } from "react";
import { UseFormMethods } from "react-hook-form";
import { useRouter } from "next/router";

import { Button } from "@components/Button";
import ChevronLeftIcon from "@components/Icons/ChevronLeftIcon";
import CloseIcon from "@components/Icons/CloseIcon";
import UnsavedChangesModal from "@components/Modals/UnsavedChangesModal";

interface Props {
  className?: string;
  useCloseIcon?: boolean;
  formMethods?: UseFormMethods;
  onClick?: () => void;
}

const BackButton: FC<Props> = ({
  className,
  useCloseIcon = false,
  formMethods,
  onClick,
}) => {
  const router = useRouter();
  const [showModal, setShowModal] = useState(false);

  if (!formMethods && window.history.length < 2 && !document.referrer)
    return (
      <Button
        className={className}
        icon={useCloseIcon ? <CloseIcon /> : <ChevronLeftIcon />}
        square
        disabled
      />
    );

  const routeBack = () => {
    if (window.history.length < 2 && document.referrer)
      router.push(document.referrer);
    else router.back();
  };

  const handleClick = () => (onClick ? onClick() : routeBack());

  return (
    <>
      <Button
        className={className}
        type="button"
        icon={useCloseIcon ? <CloseIcon /> : <ChevronLeftIcon />}
        square
        onClick={() =>
          formMethods &&
          (formMethods.formState.isDirty || formMethods.formState.isSubmitting)
            ? setShowModal(true)
            : handleClick()
        }
        data-heap-event-name="back_button_clicked"
      />
      <UnsavedChangesModal
        show={showModal}
        toggleShow={setShowModal}
        onDiscard={() => {
          formMethods.reset(formMethods.getValues());
          handleClick();
        }}
      />
    </>
  );
};

export default BackButton;
