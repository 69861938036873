import React, { FC, ReactNode } from "react";
import classNames from "classnames";

interface ContentListLayoutProps {
  children: ReactNode;
  className?: string;
  containerClassName?: string;
}

const ContentListLayout: FC<ContentListLayoutProps> = ({
  children,
  className = "",
  containerClassName = "",
}) => {
  // @TODO: Find a way to style the scrollbar. In Chrome (but not in Safari) it addes a margin that breaks the alignement with AppHeader.
  return (
    <main
      className={classNames(
        "flex-1 overflow-y-auto main pb-28 sm:pb-0",
        className
      )}
    >
      <div
        className={classNames(
          "mx-auto max-w-screen-lg px-4 md:px-8 pb-2 md:pb-6",
          containerClassName
        )}
      >
        {children}
      </div>
    </main>
  );
};

export default ContentListLayout;
