import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const FormTemplateIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4V2C6 1.44771 6.44772 1 7 1H21C21.5523 1 22 1.44772 22 2V19C22 19.5523 21.5523 20 21 20H19V22C19 22.5523 18.5523 23 18 23H4C3.44772 23 3 22.5523 3 22V5C3 4.44771 3.44772 4 4 4H6ZM8 4V3H20V18H19V5C19 4.44772 18.5523 4 18 4H8ZM5 21V6H17V21H5Z"
      />
      <rect x="7" y="9" width="2" height="2" rx="0.5" />
      <rect x="7" y="13" width="2" height="2" rx="0.5" />
      <rect x="10" y="9" width="5" height="2" rx="0.5" />
      <rect x="10" y="13" width="5" height="2" rx="0.5" />
    </svg>
  );
};

export default FormTemplateIcon;
