import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="fill-current"
        fillRule="evenodd"
        d="M7 4.83v12.677l3.119-3.564a2.5 2.5 0 013.762 0L17 17.507V4.83H7zm-1-2a1 1 0 00-1 1v16.338c0 .924 1.145 1.354 1.753.659l4.87-5.567a.5.5 0 01.753 0l4.871 5.567c.608.695 1.753.265 1.753-.659V3.83a1 1 0 00-1-1H6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Icon;
