import { FC } from "react";
import { ExtendedStripeSubscriptionPlan } from "api-services/definitions/stripe";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import useStripeInfo from "@hooks/use-stripe-info";
import { SubscriptionCouponType } from "@lib/data/schemas/subscription-plan";
import { displayItemAmountString } from "@lib/products";

import ClockIcon from "@components/Icons/ClockIcon";
import NewPackageIcon from "@components/Icons/NewPackageIcon";

import { formatPlanPaymentFrequency, formatPlanPrice } from "../Plans/utils";

import PaymentSummaryLineItem from "./PaymentSummaryLineItem";
import PaymentSummaryPaymentItem from "./PaymentSummaryPaymentItem";

export enum PaymentSummaryStyle {
  Default = "default",
  Translucent = "translucent",
}

interface PaymentSummaryProps {
  plan: ExtendedStripeSubscriptionPlan;
  coupon?: SubscriptionCouponType;
  style: PaymentSummaryStyle;
  showRecurringPayments: boolean;
  onClickChangePlan?: () => void;
  className?: string;
}

const getValueFormattedPrice = (amount: number, currency: string) =>
  displayItemAmountString(amount, currency, undefined, undefined, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0, // this line prevents a bug on some browsers
  });

const PaymentSummary: FC<PaymentSummaryProps> = ({
  plan,
  coupon,
  style = PaymentSummaryStyle.Default,
  showRecurringPayments = true,
  onClickChangePlan,
  className,
}) => {
  const { oid } = useAuth();
  const { subscription } = useStripeInfo(oid || "");

  const fullPrice = formatPlanPrice(plan, false, false, false) ?? "";
  const paymentFrequency = formatPlanPaymentFrequency(plan);
  const hasRecurringPayments = plan.frequency !== "lifetime";
  const with7DayTrial = !subscription;

  const isCouponPercentage = !!coupon?.percentOff;
  const totalDiscountValue = isCouponPercentage
    ? ((coupon?.percentOff || 0 / 100) * plan.amount) / 100
    : coupon?.amountOff || 0;
  const discountPrice = getValueFormattedPrice(
    totalDiscountValue,
    plan?.currency || ""
  );

  const renderDiscountLabel = isCouponPercentage && (
    <div className="ml-1 text-white bg-foreground/7 bg-blue-300 flex items-center justify-center px-1.5 rounded-md text-xs">
      {`${coupon?.percentOff}% off`}
    </div>
  );

  const dueTodayPrice = coupon ? (
    <>
      <span className="line-through mr-0.5">{fullPrice}</span>
      <span
        className={classNames(
          style == PaymentSummaryStyle.Translucent
            ? "text-white"
            : "text-black-ink"
        )}
      >
        {getValueFormattedPrice(
          plan.amount - totalDiscountValue,
          plan?.currency || ""
        )}
      </span>
    </>
  ) : (
    <>{fullPrice}</>
  );

  return (
    <div
      className={classNames(
        "mb-auto flex flex-col p-4 rounded-lg text-sm text-grey-500 border ",
        style == PaymentSummaryStyle.Translucent
          ? "bg-white/10 border-white/25 text-grey-800"
          : "bg-none border-grey-900 text-grey-500",
        className
      )}
    >
      <div className="flex flex-col gap-2">
        <PaymentSummaryLineItem
          title={`${plan.name} Plan price`}
          value={`${fullPrice}${paymentFrequency}`}
          discount={plan.frequency === "year" ? "-20%" : undefined}
          onClickChangePlan={onClickChangePlan}
          style={style}
        />
        {coupon && (
          <PaymentSummaryLineItem
            title={
              <span className="flex">
                {coupon.description} {renderDiscountLabel}
              </span>
            }
            value={`-${discountPrice}`}
            style={style}
          />
        )}
      </div>
      <div className="border-t my-4 border-grey-900 flex w-full"></div>
      <PaymentSummaryPaymentItem
        title={with7DayTrial ? "Due after trial" : "Due today"}
        subtitle={with7DayTrial ? "Charged in 7 days" : "Charged immediately"}
        icon={
          <NewPackageIcon
            className={classNames(
              "h-6 w-6",
              style == PaymentSummaryStyle.Translucent
                ? "text-black-ink"
                : "text-action-500"
            )}
          />
        }
        value={dueTodayPrice}
        isDueAtBooking={true}
        style={style}
      />
      {hasRecurringPayments && showRecurringPayments && (
        <PaymentSummaryPaymentItem
          title={`Following ${plan.frequency}s`}
          subtitle="Scheduled automatically"
          icon={<ClockIcon className="text-foreground/500 h-4 w-4" />}
          value={`${fullPrice}${paymentFrequency}`}
          isDueAtBooking={false}
          style={style}
        />
      )}
    </div>
  );
};

export default PaymentSummary;
