import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6666 7.15497C12.6601 6.78912 12.36 6.49682 11.9942 6.50003C11.6283 6.50324 11.3333 6.80076 11.3333 7.16667L11.3339 7.23591C11.3336 8.33636 10.7666 9.49023 9.85743 10.3837C8.94832 11.2771 7.77473 11.8333 6.66667 11.8333C6.29848 11.8333 6 12.1318 6 12.5C6 12.8682 6.29848 13.1667 6.66667 13.1667C7.78318 13.1667 8.95549 13.7316 9.86193 14.6381C10.7684 15.5445 11.3333 16.7168 11.3333 17.8333C11.3333 18.2015 11.6318 18.5 12 18.5C12.3682 18.5 12.6667 18.2015 12.6667 17.8333C12.6667 16.7168 13.2316 15.5445 14.1381 14.6381C15.0445 13.7316 16.2168 13.1667 17.3333 13.1667C17.7015 13.1667 18 12.8682 18 12.5C18 12.1318 17.7015 11.8333 17.3333 11.8333C16.2266 11.8333 15.0649 11.2783 14.1614 10.3851C13.2579 9.49196 12.6863 8.33353 12.6672 7.22441L12.6666 7.15497Z"
      />
    </svg>
  );
};

export default Icon;
