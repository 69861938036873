import { useCallback, useEffect, useRef, useState } from "react";
import {
  NotificationMessage,
  NotificationsApi,
  NotificationStatus,
} from "@practice/sdk";
import classNames from "classnames";
import throttle from "lodash/throttle";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import useLogger from "@hooks/use-logger";
import { useSDKApi } from "@hooks/use-sdk-api";
import { getNotificationLink } from "@lib/utils/notifications";

import DayIcon from "@components/Icons/DayIcon";
import MarkAsReadIcon from "@components/Icons/MarkAsReadIcon";
import LoadingSpinner from "@components/LoadingSpinner";
import NotificationPanelListItem from "@components/NotificationPanel/NotificationPanelListItem";
import {
  Tooltip,
  TooltipKind,
  TooltipSize,
} from "@components/Tooltips/Tooltip";

interface NotificationPanelListInnerProps {
  notifications: NotificationMessage[];
  onClickItemRead: (notification: NotificationMessage) => void;
  onClickMarkAllRead: () => void;
  onReachedBottomOfList: () => void;
  isFetching: boolean;
  hasReachedBottomOfList: boolean;
  className?: string;
  hasUnreadActivity?: boolean;
  isUpdatingAll?: boolean;
}

export const NotificationPanelListInner: React.FC<
  NotificationPanelListInnerProps
> = ({
  notifications,
  onClickItemRead,
  onClickMarkAllRead,
  onReachedBottomOfList,
  isFetching,
  hasReachedBottomOfList,
  className,
  hasUnreadActivity = false,
  isUpdatingAll = false,
}) => {
  const listRef = useRef<HTMLDivElement>(null);

  const { account } = useAuth();
  const router = useRouter();

  useEffect(() => {
    const currentRef = listRef.current;

    const onScroll = throttle(() => {
      if (currentRef) {
        const { scrollTop, scrollHeight, clientHeight } = currentRef;
        const isNearBottom = scrollTop + clientHeight >= scrollHeight;

        if (isNearBottom) onReachedBottomOfList();
      }
    }, 2000);

    currentRef?.addEventListener("scroll", onScroll);

    return () => currentRef?.removeEventListener("scroll", onScroll);
  }, [onReachedBottomOfList]);

  return (
    <div
      className={classNames(
        "w-[370px] min-h-[360px] h-[55vh] max-h-[960px] flex flex-col rounded-2xl overflow-hidden border border-grey-900",
        "bg-[radial-gradient(ellipse_at_bottom_center,_var(--tw-gradient-stops))] from-[#F7F0E9] via-[#F7F2EC] to-[#F7F6F5]",
        "shadow-equal-24",
        className
      )}
    >
      <div className="flex justify-between items-center gap-2 pl-4 pr-3 py-3 border-b border-b-grey-900">
        <p className="text-sm text-grey-500">Notifications</p>
        <Tooltip
          className={classNames(
            "flex",
            hasUnreadActivity ? "text-grey-500" : "text-grey-800"
          )}
          trigger={
            <button
              className="block"
              onClick={onClickMarkAllRead}
              disabled={!hasUnreadActivity}
            >
              {isUpdatingAll ? (
                <LoadingSpinner variant="transparent" width="24" height="24" />
              ) : (
                <MarkAsReadIcon />
              )}
            </button>
          }
          size={TooltipSize.larger}
          kind={TooltipKind.button}
          placement="top"
        >
          <div className="w-[110px]">
            {hasUnreadActivity
              ? "Mark all notifications as read"
              : "All notifications are marked as read"}
          </div>
        </Tooltip>
      </div>
      <div ref={listRef} className="flex-1 flex flex-col overflow-y-auto">
        {notifications.map((notification) => {
          const link = getNotificationLink(account, notification);
          const handleItemClick = (link: string) => {
            onClickItemRead(notification);
            if (link) {
              router.push(link);
            }
          };

          return (
            <NotificationPanelListItem
              key={notification.id}
              className="border-b border-b-grey-900"
              notification={notification}
              onClick={link ? () => handleItemClick(link) : undefined}
              onClickRead={() => onClickItemRead(notification)}
            />
          );
        })}
        {isFetching ? (
          <div className="flex-1 flex items-center justify-center">
            <LoadingSpinner className="py-4 opacity-50" variant="transparent" />
          </div>
        ) : hasReachedBottomOfList && notifications.length < 1 ? (
          <div className="flex flex-col gap-2 px-4 py-12 items-center text-grey-500">
            <DayIcon />
            <p className="text-sm">You’re all caught up!</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

interface NotificationPanelListProps {
  className?: string;
  onUpdate?: Function;
  hasUnreadActivity?: boolean;
}

const NotificationPanelList: React.FC<NotificationPanelListProps> = ({
  className,
  onUpdate,
  hasUnreadActivity = false,
}) => {
  const [notifications, setNotifications] = useState<NotificationMessage[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isUpdatingAll, setIsUpdatingAll] = useState<boolean>(false);
  const [hasReachedBottomOfList, setHasReachedBottomOfList] =
    useState<boolean>(false);
  const [next, setNext] = useState<string | undefined>(undefined);
  const { logger } = useLogger();

  const notificationsApi = useSDKApi(NotificationsApi);

  const { aid } = useAuth();
  if (!aid) {
    throw Error("Missing AccountId in NotificationPanelList");
  }

  const fetchNotifications = useCallback(async () => {
    try {
      setIsFetching(true);

      const response = await notificationsApi.listNotifications({
        limit: 10,
        accountId: aid,
        after: next,
      });

      setNotifications((notifications) => {
        return notifications.concat(
          (response.data as NotificationMessage[]).filter(
            (notification) => notifications.indexOf(notification) < 0
          )
        );
      });

      if (response.last) {
        setNext(response.last);
      } else {
        setHasReachedBottomOfList(true);
      }

      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      logger.error(error, "Error fetching notifications");
    } finally {
      setIsFetching(false);
    }
  }, [next, notificationsApi]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const onClickItemRead = async (notification: NotificationMessage) => {
    const newStatus =
      notification.status === NotificationStatus.Read
        ? NotificationStatus.Unread
        : NotificationStatus.Read;
    const result = await notificationsApi.updateNotificationMessages({
      accountId: aid,
      updateNotificationMessagesRequest: {
        notificationMessages: [
          {
            ...notification,
            status: newStatus,
          },
        ],
      },
    });
    // Update existing notification state
    if (result.success) {
      notification.status = newStatus;
      const i = notifications.findIndex((n) => n.id === notification.id);
      notifications[i] = notification;
      fetchNotifications();
    }
    setIsFetching(false);
  };

  const onClickMarkAllRead = async () => {
    setIsFetching(true);
    setIsUpdatingAll(true);
    const result = await notificationsApi.updateAllNotificationMessages({
      accountId: aid,
      updateAllNotificationMessagesRequest: {
        status: NotificationStatus.Read,
      },
    });
    // Update existing notification state
    if (result.success) {
      const updatedNotifications = notifications.map((notification) => ({
        ...notification,
        status: NotificationStatus.Read,
      }));
      setNotifications(updatedNotifications);
      fetchNotifications();
    }
    setIsFetching(false);
    setIsUpdatingAll(false);
  };

  return (
    <NotificationPanelListInner
      className={className}
      notifications={notifications}
      onClickItemRead={onClickItemRead}
      onClickMarkAllRead={onClickMarkAllRead}
      onReachedBottomOfList={fetchNotifications}
      isFetching={isFetching}
      hasReachedBottomOfList={hasReachedBottomOfList}
      hasUnreadActivity={hasUnreadActivity}
      isUpdatingAll={isUpdatingAll}
    />
  );
};

export default NotificationPanelList;
