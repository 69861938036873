import { getSchemaDefinition } from "api-services/common";
import { z } from "zod";

const tags = ["feature-flags"];

export const FeatureFlagKey = z.enum(["requirePaymentInGoFlow"]);

const QuerySchema = z.object({
  featureFlagKey: FeatureFlagKey,
});

export const getFeatureFlagValue = getSchemaDefinition(
  "/api/v1/feature-flags",
  "get",
  {
    query: QuerySchema,
  },
  {
    description: "Get the current boolean value for a feature flag key.",
    tags,
  },
  z.object({ value: z.boolean() })
);
