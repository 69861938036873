import { FC, useEffect, useMemo, useState } from "react";
import {
  ExtendedStripeSubscriptionPlan,
  getStripeSubscriptionPlans,
} from "api-services/definitions/stripe";
import { useApiGet } from "api-services/endpoints";

import { useAuth } from "@contexts/auth";
import { useIsMobile } from "@hooks/use-window-size";
import { getTrialRemainingStatus } from "@lib/utils/subscription-plans";

import LoadingSpinner from "@components/LoadingSpinner";
import { FeedbackModal } from "@components/Modals";
import BigModal from "@components/Modals/BigModal";
import ComparePlanContent from "@components/Plans/ComparePlanContent";
import { formatPlanPrice } from "@components/Plans/utils";

import { FreeTrialConversionContainerProps } from "./FreeTrialConversionContainer";

interface FreeTrialConversionContentProps {
  variant: FreeTrialConversionContainerProps["variant"];
  withPlanComparison?: boolean;
  onDismiss?: () => void;
  onClickContinue: (plan: ExtendedStripeSubscriptionPlan) => void;
  onClickCompare: () => void;
}

const FreeTrialConversionContent: FC<FreeTrialConversionContentProps> = ({
  variant,
  withPlanComparison = true,
  onDismiss,
  onClickContinue,
  onClickCompare,
}) => {
  const { hasTrialEnded, subscription } = useAuth();

  const isMobile = useIsMobile();

  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
  const [plan, setPlan] = useState<ExtendedStripeSubscriptionPlan>();
  const [discountPrice, setDiscountPrice] = useState<string | number>("");

  const { data: subscriptionPlans } = useApiGet(
    getStripeSubscriptionPlans,
    {},
    {}
  );

  useEffect(() => {
    plan?.defaultCoupon &&
      setDiscountPrice &&
      setDiscountPrice(formatPlanPrice(plan, true, true) || "");
  }, [plan, setDiscountPrice]);

  useEffect(() => {
    const proMonthly = subscriptionPlans?.find(
      (plan) => plan.frequency === "month" && plan.tier === "pro"
    );
    setPlan(proMonthly);
  }, [subscriptionPlans]);

  const trialRemainingStatus = useMemo(
    () => getTrialRemainingStatus(subscription),
    [subscription]
  );

  const title = `Reactivate your account ${
    discountPrice && !isMobile ? ` — Continue for ${discountPrice}` : ""
  }`;
  const descriptionFirst =
    trialRemainingStatus && `Your trial ${trialRemainingStatus.text}`;
  const descriptionSecond = trialRemainingStatus?.hasEnded
    ? "Subscribe now or your account will be paused after 30 days"
    : "Subscribe now to keep using Practice";
  const description = descriptionFirst
    ? `${descriptionFirst} — ${descriptionSecond}`
    : descriptionSecond;

  const renderContent = () => (
    <div className="flex justify-center sm:flex-none">
      {!plan ? (
        <LoadingSpinner className="my-20 sm:my-56" variant="transparent" />
      ) : (
        <ComparePlanContent
          plan={plan}
          withPlanComparison={withPlanComparison}
          onClickContinue={() => onClickContinue(plan)}
          onClickCompare={onClickCompare}
        />
      )}
    </div>
  );

  return (
    <>
      {variant === "modal" ? (
        <BigModal
          show={true}
          title={title}
          description={description}
          disableClose={!subscription || hasTrialEnded}
          toggleShow={onDismiss}
          size="bigger"
          headerButtons={[
            {
              text: "Contact us",
              onClick: () => setShowFeedbackModal(true),
            },
          ]}
        >
          {renderContent()}
        </BigModal>
      ) : (
        renderContent()
      )}

      <FeedbackModal
        showModal={showFeedbackModal}
        setShowModal={setShowFeedbackModal}
      />
    </>
  );
};

export default FreeTrialConversionContent;
