import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe, SetupIntent } from "@stripe/stripe-js";
import {
  ExtendedStripeSubscriptionPlan,
  postStripeSubscriptionValidatePromoCode,
} from "api-services/definitions/stripe";
import { updateUserOnboardingValues } from "api-services/definitions/user";
import { api, useApi } from "api-services/endpoints";
import axios from "axios";
import { useRouter } from "next/router";
import { useSWRConfig } from "swr";

import { useAuth } from "@contexts/auth";
import useSnackbar from "@hooks/use-snackbar";
import useStripeInfo from "@hooks/use-stripe-info";
import analytics from "@lib/analytics";
import { SubscriptionTypeEnum } from "@lib/data/schemas/subscription";
import { SubscriptionPlanType } from "@lib/data/schemas/subscription-plan";
import { cleanUpUrl } from "@lib/utils";

const Validator: FC<{
  clientSecret: string;
  onValidated: (setupIntent: SetupIntent) => void;
}> = ({ clientSecret, onValidated }) => {
  const stripe = useStripe();

  const snackbar = useSnackbar();

  useEffect(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    snackbar.showMessage("Validating...");
    stripe.retrieveSetupIntent(clientSecret).then(async ({ setupIntent }) => {
      !setupIntent
        ? snackbar.showWarning("Please try again", "Setup intent fail")
        : onValidated(setupIntent);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSecret, stripe]);

  return null;
};

export type PaymentMethodWrapperProps = {
  children: ReactNode;
  onValidated: (setupIntent: SetupIntent) => void;
};

export const PaymentMethodWrapper: FC<PaymentMethodWrapperProps> = ({
  children,
  onValidated,
}) => {
  const [clientSecret, setClientSecret] = useState();
  const { uid, oid } = useAuth();
  const { isLoadingSubscription } = useStripeInfo(oid || "");

  const stripePublicKey = process.env.stripe_public_key;

  const stripePromise = useMemo(
    () => stripePublicKey && loadStripe(stripePublicKey),
    [stripePublicKey]
  );

  useEffect(() => {
    const get = async () => {
      const response = await axios.post(
        `/api/v1/users/${uid}/stripe/setup-intent`
      );
      const { setupIntent } = response.data;
      setClientSecret(setupIntent.clientSecret);
    };

    uid && get();
  }, [uid]);

  const router = useRouter();

  const {
    query: { setup_intent_client_secret: urlClientSecret },
  } = router;

  if (
    (!clientSecret && !urlClientSecret) ||
    !stripePromise ||
    !uid ||
    isLoadingSubscription
  ) {
    return null;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: clientSecret || (urlClientSecret as string) }}
    >
      {urlClientSecret && (
        <Validator
          onValidated={onValidated}
          clientSecret={urlClientSecret as string}
        />
      )}
      {!urlClientSecret && children}
    </Elements>
  );
};

export const usePaymentMethodConfirmationForm = (
  plan?: ExtendedStripeSubscriptionPlan
) => {
  const stripe = useStripe();
  const elements = useElements();

  const { apiCall: validatePromoCode } = useApi(
    postStripeSubscriptionValidatePromoCode
  );

  const [errorMessage, setErrorMessage] = useState<string>();

  const [loading, setLoading] = useState(false);
  let promoCodeId: string;

  const handleSubmit =
    (data: { hasPromoCode: boolean; promoCode?: string }) =>
    async (event: React.SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      setLoading(true);

      if (data.hasPromoCode && data.promoCode) {
        try {
          // @TODO: our generic functions is returning an invalid type, not considering
          //        the `data` prop from axios
          const { data: validationResult } = await validatePromoCode(
            { promoCode: data.promoCode },
            {},
            {}
          );

          if (!validationResult.valid) {
            throw new Error("The code is not valid.");
          }
          promoCodeId = validationResult.promoCodeId;
        } catch (error) {
          setErrorMessage(
            `Error validating your promotion code: ${error?.message || error}`
          );
          setLoading(false);
          return;
        }
      }

      const returnParams = new URLSearchParams();
      if (plan) {
        returnParams.set("planInformation", JSON.stringify(plan));
      }
      if (promoCodeId) returnParams.set("promoCodeId", promoCodeId);
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${location.href}${
            location.href?.includes("?") ? "&" : "?"
          }${returnParams.toString()}`,
        },
      });

      if (error) {
        setErrorMessage(error?.message || "");
        setLoading(false);
      }
    };

  return { handleSubmit, loading, errorMessage };
};

const track = (event: string, properties: any = {}, userId: string) => {
  analytics.track({ event, userId, properties });
};

export const PlanPaymentWrapper: React.FC<{
  children: ReactNode;
  omitGoFlow?: boolean;
}> = ({ children, omitGoFlow }) => {
  const { uid: userId } = useAuth();
  const { mutate } = useSWRConfig();

  const snackBar = useSnackbar();
  const router = useRouter();

  const { promoCodeId, referredBy, planInformation } = router.query;

  const planInfo = JSON.parse(
    (planInformation as string) || "null"
  ) as SubscriptionPlanType | null;

  const onValidated: PaymentMethodWrapperProps["onValidated"] = useCallback(
    async (setupIntent) => {
      switch (setupIntent.status) {
        case "succeeded":
          snackBar.showMessage(
            "Success!",
            "Your payment method has been saved."
          );

          try {
            const heapProperties = {
              subscriptionType:
                planInfo?.frequency === "year"
                  ? SubscriptionTypeEnum.AnnualPlan
                  : SubscriptionTypeEnum.MonthlyPlan,
            };
            await axios.post(`/api/v1/users/${userId}/stripe/subscriptions`, {
              paymentMethodId: setupIntent.payment_method,
              promoCodeId: promoCodeId,
              referredBy: referredBy,
              priceId: planInfo?.priceId,
            });
            // @TODO: maybe we can RIP those heap events. All information
            //        is added as prop from `coach_subscription_plan_selected`
            if (referredBy) {
              track("referral_code_used", heapProperties, userId);
            } else if (promoCodeId) {
              track("promo_code_used", heapProperties, userId);
            }
            snackBar.showMessage("Success!", "You are subscribed to Practice.");
            track(
              "coach_subscription_plan_selected",
              {
                ...heapProperties,
                withPromoCode: !!promoCodeId,
                wasReferred: !!referredBy,
              },
              userId
            );

            await mutate(
              (key: any) =>
                key.startsWith?.(`/api/v1/users/${userId}/authorization/`)
            );

            await mutate(
              (key: any) => key.startsWith?.(`/api/v1/users/${userId}/stripe/`)
            );
            cleanUpUrl();
            router.push({
              pathname: router.pathname,
            });
            if (!omitGoFlow) {
              await api(updateUserOnboardingValues, {
                path: { userId },
                body: { completedGoFlowTimeToShine: null },
              });
            }
          } catch (error) {
            snackBar.showWarning("Error creating subscription", error.message);
          }
          break;

        case "processing":
          snackBar.showMessage(
            "Success!",
            "Processing payment details. We'll update you when processing is complete."
          );
          break;
        case "requires_payment_method":
          snackBar.showWarning(
            "Failed to process payment details",
            "Please try another payment method"
          );
          cleanUpUrl();
          router.push({
            pathname: router.pathname,
          });
          break;
      }
    },
    [
      mutate,
      omitGoFlow,
      planInfo?.frequency,
      planInfo?.priceId,
      promoCodeId,
      referredBy,
      router,
      snackBar,
      userId,
    ]
  );

  return (
    <PaymentMethodWrapper onValidated={onValidated}>
      {children}
    </PaymentMethodWrapper>
  );
};
