import { FC, useEffect, useState } from "react";
import { ExtendedStripeSubscriptionPlan } from "api-services/definitions/stripe";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";

import FreeTrialConversionContent from "@components/Client/FreeTrialConversionContent";
import WelcomeProModal from "@components/Modals/TiersWelcomeModal";
import PaymentMethodWallModal from "@components/PaymentMethodWall/PaymentMethodWall";
import ComparePlanModal from "@components/Plans/ComparePlanModal";

enum FreeTrialConversionStep {
  limitedOffer = 1,
  comparePlans,
  addPaymentMethod,
}

export interface FreeTrialConversionContainerProps {
  variant: "page" | "modal";
  withPlanComparison?: boolean;
  onDismiss?: () => void;
  show?: boolean;
  hasAccountPaymentMethod?: boolean;
}

const FreeTrialConversionContainer: FC<FreeTrialConversionContainerProps> = ({
  variant,
  hasAccountPaymentMethod,
  withPlanComparison = true,
  onDismiss,
  show,
}) => {
  const { firestoreUser, subscriptionPlan, hasTrialEnded } = useAuth();
  const router = useRouter();
  const [currentStep, setCurrentStep] =
    useState<FreeTrialConversionStep | null>(
      hasAccountPaymentMethod
        ? FreeTrialConversionStep.addPaymentMethod
        : FreeTrialConversionStep.limitedOffer
    );

  const [selectedPlan, setSelectedPlan] = useState<
    ExtendedStripeSubscriptionPlan | undefined
  >(undefined);

  useEffect(() => {
    if (hasAccountPaymentMethod) {
      setCurrentStep(FreeTrialConversionStep.addPaymentMethod);
      if (subscriptionPlan) {
        setSelectedPlan({
          ...subscriptionPlan,
          amount: subscriptionPlan?.amount / 100,
        });
      }
    }
  }, [hasAccountPaymentMethod, subscriptionPlan]);

  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(false);

  const {
    query: { setup_intent_client_secret: urlClientSecret, promoCodeId },
  } = router;

  useEffect(() => {
    const searchParams = new URLSearchParams(window?.location?.search);
    const planFromUrl = searchParams.get("planInformation");

    if (planFromUrl) {
      setSelectedPlan(JSON.parse(planFromUrl));
      setCurrentStep(FreeTrialConversionStep.addPaymentMethod);
    }
  }, []);

  const handleSelectPlan = (value: ExtendedStripeSubscriptionPlan) => {
    setSelectedPlan(value);
    setCurrentStep(FreeTrialConversionStep.addPaymentMethod);
  };

  const handleSuccess = () => {
    if (router.pathname === "/start-trial") {
      router.replace("/time-to-shine");
    } else {
      setShowWelcomeModal(true);
    }
  };

  if (showWelcomeModal && firestoreUser?.subscription?.tier === "pro") {
    return (
      <WelcomeProModal
        variant="pro"
        onDismiss={() => setShowWelcomeModal(false)}
      />
    );
  }

  if (!show && variant === "modal" && !urlClientSecret && !hasTrialEnded)
    return null;

  return (
    <>
      {(variant === "page" ||
        currentStep === FreeTrialConversionStep.limitedOffer) && (
        <FreeTrialConversionContent
          variant={variant}
          withPlanComparison={withPlanComparison}
          onDismiss={onDismiss}
          onClickContinue={handleSelectPlan}
          onClickCompare={() =>
            setCurrentStep(FreeTrialConversionStep.comparePlans)
          }
        />
      )}
      {currentStep === FreeTrialConversionStep.comparePlans && (
        <ComparePlanModal
          show
          toggleShow={() =>
            setCurrentStep(FreeTrialConversionStep.limitedOffer)
          }
          onSelectPlan={handleSelectPlan}
          useBackIcon={true}
        />
      )}
      {currentStep === FreeTrialConversionStep.addPaymentMethod && (
        <PaymentMethodWallModal
          show
          plan={selectedPlan}
          promoCodeId={promoCodeId as string}
          onBack={() => {
            setCurrentStep(FreeTrialConversionStep.limitedOffer);
            setSelectedPlan(undefined);
          }}
          onSuccess={handleSuccess}
        />
      )}
    </>
  );
};

export default FreeTrialConversionContainer;
