import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ThunderIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.5 10C13.2239 10 13 9.77614 13 9.5L13 4.71703C13 4.2168 12.3467 4.02657 12.0782 4.44859L6.489 13.2316C6.27718 13.5644 6.51629 14 6.91084 14H10.5C10.7761 14 11 14.2239 11 14.5L11 19.283C11 19.7832 11.6533 19.9734 11.9218 19.5514L17.511 10.7684C17.7228 10.4356 17.4837 10 17.0892 10L13.5 10Z" />
    </svg>
  );
};

export default ThunderIcon;
