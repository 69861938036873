import { FC, useEffect } from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";
import classNames from "classnames";

import { Button } from "@components/Button";
import TextFieldForm from "@components/Form/TextFieldForm";

export interface CouponFieldsFormDataType {
  hasPromoCode: boolean;
  promoCode?: string;
}

interface CouponFormFieldsProps<TFieldValues extends FieldValues> {
  className?: string;
  methods: UseFormReturn<TFieldValues>;
  onChangeCoupon?: (value: string) => void;
}

const CouponFields: FC<CouponFormFieldsProps<CouponFieldsFormDataType>> = ({
  className,
  methods,
  onChangeCoupon,
}) => {
  const {
    register,
    formState: { errors },
    watch,
  } = methods;
  const promoCode = watch("promoCode");

  const handleApplyPromoCode = () => {
    promoCode && onChangeCoupon && onChangeCoupon(promoCode);
  };

  useEffect(() => {
    if (!promoCode) {
      onChangeCoupon && onChangeCoupon("");
    }
  }, [promoCode]);

  const applyButton = (
    <Button
      variant="default"
      className="border rounded-md rounded-l-none border-l-0 border-grey-900 cursor-pointer px-4"
      onClick={handleApplyPromoCode}
    >
      Apply
    </Button>
  );

  return (
    <FormProvider {...methods}>
      <div className={classNames("flex items-center mt-5", className)}>
        <TextFieldForm
          placeholder="Promo code"
          name="promoCode"
          register={register}
          containerClassName="relative w-full"
          inputClassName="!mt-0 hover:bg-white hover:!border-grey-900"
          errors={errors}
          required
          rightComponent={applyButton}
        />
      </div>
    </FormProvider>
  );
};

export default CouponFields;
