import { ReactNode } from "react";

import { profileOGImage } from "@lib/og";
import { SanitizedUserType } from "@lib/shared-types";

import ClientContainerLayout from "@components/Client/ClientContainerLayout";
import ClientFooterLayout from "@components/Client/ClientFooterLayout";
import ClientLayout from "@components/Client/ClientLayout";
import MetaHead from "@components/MetaHead";

type Props = {
  metaTitle?: string;
  metaSubtitle?: string;
  metaRobots?: string;
  coach?: SanitizedUserType;
  aboveTitle?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  content?: ReactNode;
  buttons?: ReactNode;
};

const AuthPageShell: React.FC<Props> = ({
  metaTitle,
  metaSubtitle,
  metaRobots,
  coach,
  aboveTitle,
  title,
  subtitle,
  content,
  buttons,
}) => (
  <ClientLayout>
    <MetaHead
      title={
        metaTitle || (typeof title === "string" ? (title as string) : undefined)
      }
      subTitle={metaSubtitle}
      description={undefined}
      robots={metaRobots}
      image={profileOGImage(coach?.avatarURL)}
    />
    <ClientContainerLayout
      coach={coach}
      onlyThemedIfHasWhiteLabel
      hideLoginButton
    >
      <div className="w-full flex flex-col items-center max-w-sm py-12 px-6">
        {aboveTitle}

        <div className="w-full space-y-2 my-6">
          {title && (
            <h1 className="text-3xl text-center font-regular leading-10">
              {title}
            </h1>
          )}
          {subtitle && <p className="text-xl text-center">{subtitle}</p>}
        </div>

        {content}

        {buttons && (
          <div className="flex flex-col w-full space-y-2 mt-6">{buttons}</div>
        )}
      </div>
    </ClientContainerLayout>
    <ClientFooterLayout coach={coach} />
  </ClientLayout>
);

export default AuthPageShell;
