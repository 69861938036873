import React from "react";
import classNames from "classnames";

const ClientLayout = ({ children }) => {
  return (
    <div className={classNames("bg-background text-foreground")}>
      <div className="min-h-screen flex flex-col">{children}</div>
    </div>
  );
};

export default ClientLayout;
