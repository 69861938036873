import { FC, ReactNode } from "react";
import classNames from "classnames";

import Tag from "@components/Tag/Tag";

import { PaymentSummaryStyle } from "./PaymentSummary";

interface PaymentSummaryLineItemProps {
  title: ReactNode;
  value: string;
  discount?: string;
  onClickChangePlan?: () => void;
  style: PaymentSummaryStyle;
}

const PaymentSummaryLineItem: FC<PaymentSummaryLineItemProps> = ({
  title,
  value,
  discount,
  onClickChangePlan,
  style,
}) => {
  return (
    <div className="flex items-center justify-between space-x-1">
      <div className="flex items-center gap-2">
        <div>
          <h3
            className={classNames(
              "flex items-center space-x-1 text-sm",
              style == PaymentSummaryStyle.Translucent
                ? "text-grey-800"
                : "text-grey-500"
            )}
          >
            <span>{title}</span>

            {discount && <Tag color="blue">{discount}</Tag>}

            {onClickChangePlan && (
              <button onClick={onClickChangePlan} className="underline">
                Change plan
              </button>
            )}
          </h3>
        </div>
      </div>

      <div className="text-right">
        <div
          className={classNames(
            "font-medium text-sm",
            style == PaymentSummaryStyle.Translucent
              ? "text-grey-800"
              : "text-grey-500"
          )}
        >
          {value}
        </div>
      </div>
    </div>
  );
};

export default PaymentSummaryLineItem;
