import { FC } from "react";
import { ExtendedStripeSubscriptionPlan } from "api-services/definitions/stripe";
import classNames from "classnames";
import moment from "moment";

import { useAuth } from "@contexts/auth";
import { useIsMobile } from "@hooks/use-window-size";
import { SubscriptionCouponType } from "@lib/data/schemas/subscription-plan";

import LiveChatSupportBanner from "@components/Banner/LiveChatSupportBanner/LiveChatSupportBanner";
import SmallBanner from "@components/Banner/SmallBanner";
import { Button } from "@components/Button";
import BlockIcon from "@components/Icons/BlockIcon";
import ChevronLeftIcon from "@components/Icons/ChevronLeftIcon";
import ClientIcon from "@components/Icons/ClientIcon";
import ClockIcon from "@components/Icons/ClockIcon";
import CreditCardIcon from "@components/Icons/CreditCardIcon";
import LockIcon from "@components/Icons/LockIcon";
import OnlineIcon from "@components/Icons/OnlineIcon";

import PaymentSummary, { PaymentSummaryStyle } from "./PaymentSummary";

export type AccountInfoStatusType =
  | "INVALID_PAYMENT"
  | "ACCOUNT_PAUSED"
  | "ADD_PAYMENT";

interface AccountInfoProps {
  className?: string;
  status: AccountInfoStatusType;
  paymentFailedDate?: Date;
  plan?: ExtendedStripeSubscriptionPlan;
  onBack?: () => void;
  coupon?: SubscriptionCouponType;
  isPromoCodeInvalid?: boolean;
}

const AccountInfo: FC<AccountInfoProps> = ({
  className,
  status: accountStatus,
  paymentFailedDate,
  plan,
  onBack,
  coupon,
  isPromoCodeInvalid = false,
}) => {
  const isMobile = useIsMobile();
  const { subscription } = useAuth();
  /**
   * The accurancy is not perfect because we are using the stripe smart retries
   * and we don't have control over when the last retry will happen.
   * The value of the three weeks was set here based on the stripe
   * billing settings page:
   * https://dashboard.stripe.com/settings/billing/automatic
   **/
  const whenAccountWillBePaused =
    paymentFailedDate && moment(paymentFailedDate).add(1, "month");

  const data = {
    INVALID_PAYMENT: {
      icon: CreditCardIcon,
      title: "Missing payment method",
      subtitle:
        "We have stopped all automations and public pages. Add a valid payment method to unpause your account.",
    },
    ACCOUNT_PAUSED: {
      icon: ClockIcon,
      title: "Your account is paused",
      subtitle:
        "Your automations are currently paused. To resume them, please add a valid credit card.",
    },
    ADD_PAYMENT: {
      icon: null,
      title: "Add payment method",
      subtitle: subscription
        ? "Keep your Practice account accessible by adding a valid payment method"
        : null,
    },
  };
  const { title, subtitle, icon: Icon } = data[accountStatus];

  return (
    <div className={classNames("flex flex-col gap-4", className)}>
      {onBack && (
        <Button
          className="self-start rounded-lg"
          icon={<ChevronLeftIcon className="w-6 h-6" />}
          square
          onClick={onBack}
        />
      )}
      {Icon && (
        <div className="self-start bg-action-950 p-3 rounded-full">
          <Icon className="w-8 h-8 text-action-500" />
        </div>
      )}

      <h1 className="text-2xl leading-tight text-black-ink">{title}</h1>
      {subtitle && <p className="text-md text-grey-500">{subtitle}</p>}
      {isPromoCodeInvalid && (
        <SmallBanner
          variant="error"
          items={[{ Icon: BlockIcon, text: "This promo code is invalid" }]}
        />
      )}
      {accountStatus === "INVALID_PAYMENT" && (
        <SmallBanner
          variant="alert"
          className="mb-auto"
          items={[
            {
              Icon: OnlineIcon,
              text: (
                <>
                  If no action is taken, your account will be paused on{" "}
                  {whenAccountWillBePaused && (
                    <span className="font-medium">
                      {whenAccountWillBePaused.format("MMMM Do Y")}
                    </span>
                  )}
                </>
              ),
            },
          ]}
        />
      )}
      {accountStatus === "ACCOUNT_PAUSED" && (
        <SmallBanner
          className="mb-auto"
          items={[
            {
              Icon: ClientIcon,
              text: "Client-facing schedulers and forms are currently not accessible.",
            },
            {
              Icon: LockIcon,
              text: "Client portals are currently locked.",
            },
          ]}
        />
      )}
      {accountStatus === "ADD_PAYMENT" && plan && (
        <PaymentSummary
          plan={plan}
          coupon={coupon}
          style={PaymentSummaryStyle.Default}
          showRecurringPayments={true}
        />
      )}

      {!isMobile && <LiveChatSupportBanner />}
    </div>
  );
};

export default AccountInfo;
