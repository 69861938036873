import React, { FC, ReactNode, useState } from "react";
import classNames from "classnames";
import { kebabCase } from "lodash";

import { LayoutVariantType } from "@lib/shared-types";

import CollapsedHeader from "@components/DisplayDetails/CollapsedHeader";
import { DropdownMenu, DropdownMenuItem } from "@components/DropdownMenu";
import PlusIcon from "@components/Icons/PlusIcon";

export type MenuActionType = ActionType | ParentActionType;

interface Props {
  variant?: LayoutVariantType;
  actions: MenuActionType[];
  openHeaderDropdown?: boolean;
  setOpenHeaderDropdown?: any;
}

export type ActionType = {
  icon: ReactNode;
  text: string;
  onClick?: () => void;
  href?: string;
  heapEventName?: string;
};

export type ParentActionType = {
  parentTitle: string;
  actions: ActionType[];
  icon?: ReactNode;
};

const ActionsMenu: FC<Props> = ({
  actions,
  variant = "default",
  openHeaderDropdown = false,
  setOpenHeaderDropdown,
}) => {
  const [open, setOpen] = useState(openHeaderDropdown);
  const [openParents, setOpenParents] = useState<Record<string, boolean>>({});

  if (!actions?.length) return null;

  const renderDropDownItem = (
    action: ActionType,
    indexId: string,
    isFirst?: boolean,
    isLast?: boolean,
    className?: string
  ) => (
    <DropdownMenuItem
      closeDropdownOnClick
      {...action}
      key={`dropdown-key-item-${indexId}`}
      className={classNames("w-full", className)}
      setIsOpen={setOpen}
      isFirst={isFirst}
      isLast={isLast}
    />
  );

  const renderParentAction = (parentAction: ParentActionType) => {
    const { parentTitle, icon, actions } = parentAction;
    const isOpen = openParents[parentTitle];

    return (
      <div
        className={classNames(
          "p-2",
          isOpen ? "bg-action-600" : "border-b border-black-ink/10"
        )}
      >
        <div
          className={classNames("flex items-center gap-2 cursor-pointer p-3")}
          onClick={() =>
            setOpenParents({
              ...openParents,
              [parentTitle]: !openParents[parentTitle],
            })
          }
        >
          <CollapsedHeader
            toggleCollapsed={(collapsed) =>
              setOpenParents({
                ...openParents,
                [parentTitle]: collapsed,
              })
            }
            collapsed={!isOpen}
            className="flex justify-between w-full"
            iconClassNames="text-action-300/50"
            leftElement={
              <div className="flex items-center gap-3">
                {icon} {parentTitle}
              </div>
            }
          />
        </div>

        {isOpen &&
          actions.map((action, index) => {
            const isFirst = index === 0;
            const isLast = index === actions.length - 1;
            return renderDropDownItem(
              action,
              `${kebabCase(parentTitle)}-${index}`,
              isFirst,
              isLast,
              "bg-action-700 hover:!bg-action-700/70"
            );
          })}
      </div>
    );
  };

  return (
    <DropdownMenu
      id="actions-menu-button"
      icon={<PlusIcon />}
      size="medium"
      variant="primary"
      shadowClass="shadow-equal-20"
      open={
        openHeaderDropdown && setOpenHeaderDropdown ? openHeaderDropdown : open
      }
      setOpen={setOpenHeaderDropdown ?? setOpen}
      buttonShape={variant === "record" ? "square-on-mobile" : "circle"}
    >
      {actions.map((action, index) => {
        const isParent = action?.parentTitle;
        return isParent
          ? renderParentAction(action as ParentActionType)
          : renderDropDownItem(action as ActionType, `${index}`);
      })}
    </DropdownMenu>
  );
};

export default ActionsMenu;
