import { FC } from "react";
import {
  ActivityAction,
  NotificationMessage,
  NotificationType,
  ResourceType,
} from "@practice/sdk";
import compact from "lodash/compact";

import { SVGIconProps } from "@lib/shared-types";
import { getCoachBaseUrl, getCurrentURIFromServer } from "@lib/utils";

import ArchiveIcon from "@components/Icons/ArchiveIcon";
import AssignIcon from "@components/Icons/AssignIcon";
import CalendarIcon from "@components/Icons/CalendarIcon";
import ChatIcon from "@components/Icons/ChatIcon";
import ClientIcon from "@components/Icons/ClientIcon";
import DocTextIcon from "@components/Icons/DocTextIcon";
import FolderIcon from "@components/Icons/FolderIcon";
import FormTemplateIcon from "@components/Icons/FormTemplateIcon";
import GroupIcon from "@components/Icons/GroupIcon";
import InvoiceIcon from "@components/Icons/InvoiceIcon";
import NoteIcon from "@components/Icons/NoteIcon";
import NotificationIcon from "@components/Icons/NotificationIcon";
import PackageIcon from "@components/Icons/PackageIcon";
import ServicesIcon from "@components/Icons/ServicesIcon";
import ThunderIcon from "@components/Icons/ThunderIcon";
import UnarchiveIcon from "@components/Icons/UnarchiveIcon";

const actionMessage: Record<ActivityAction, string> = {
  [ActivityAction.Create]: "was created by",
  [ActivityAction.Update]: "was updated by",
  [ActivityAction.Delete]: "was deleted by",
  [ActivityAction.Read]: "was read by",
  [ActivityAction.Relate]: "was assigned to",
  [ActivityAction.Unrelate]: "was unassigned from",
  [ActivityAction.Archive]: "was archived by",
  [ActivityAction.Unarchive]: "was unarchived by",
  [ActivityAction.Complete]: "was completed by",
  [ActivityAction.Cancel]: "was canceled by",
  [ActivityAction.Reschedule]: "was rescheduled by",
  [ActivityAction.OutcomeUpdated]: "was updated by",
  [ActivityAction.Pause]: "was paused by",
  [ActivityAction.Resume]: "was resumed by",
  // TODO: we probably need to add some more actions to the SDK, e.g. `Paid`, `Joined`, etc.
};

export function getNotificationIcon(
  notification: NotificationMessage
): string | FC<SVGIconProps> {
  const { type, preview } = notification.resource ?? {};

  if (preview?.imageUrl) return preview.imageUrl;

  // Some actions have their own icons
  switch (notification.action) {
    case ActivityAction.Relate:
    case ActivityAction.Unrelate:
      return AssignIcon;
    case ActivityAction.Archive:
      return ArchiveIcon;
    case ActivityAction.Unarchive:
      return UnarchiveIcon;
  }

  switch (type) {
    case ResourceType.Account:
    case ResourceType.Client:
      return ClientIcon;
    case ResourceType.Appointment:
      return CalendarIcon; // TODO: check with designers
    case ResourceType.Availability:
      return CalendarIcon; // TODO: check with designers
    case ResourceType.File:
      return DocTextIcon; // TODO: check with designers
    case ResourceType.Folder:
      return FolderIcon;
    case ResourceType.Form:
      return FormTemplateIcon; // TODO: check with designers
    case ResourceType.Invoice:
      return InvoiceIcon;
    case ResourceType.Message:
      return ChatIcon;
    case ResourceType.Note:
      return NoteIcon;
    case ResourceType.Organization:
      return GroupIcon; // TODO: check with designers
    case ResourceType.Package:
      return PackageIcon;
    case ResourceType.Product:
      return ServicesIcon; // TODO: check with designers
    case ResourceType.Scheduler:
      return CalendarIcon; // TODO: check with designers
    case ResourceType.Smartaction:
      return ThunderIcon;
  }

  return NotificationIcon; // TODO: check with designers
}

export function getNotificationLink(
  account: any,
  notification: NotificationMessage
): string | undefined {
  // External link
  if (notification.resource?.preview?.linkUrl) {
    return notification.resource?.preview?.linkUrl;
  }

  const type = notification.resource?.type;
  const actorId = notification.actor?.id;
  const resourceId = notification.resource?.id;
  const orgUrl = getCoachBaseUrl(account);
  const baseUrl = getCurrentURIFromServer();

  switch (type) {
    case ResourceType.Account:
      return `${baseUrl}/teams/`;
    case ResourceType.Client:
      return `${baseUrl}/contacts/${resourceId}`;
    case ResourceType.Appointment:
      return `${baseUrl}/appointments/${resourceId}`;
    case ResourceType.Availability:
      return `${baseUrl}/availabilities/${resourceId}`;
    case ResourceType.File:
      return `${baseUrl}/library/files/${resourceId}`;
    case ResourceType.Folder:
      return `${baseUrl}/library/folders/${resourceId}`;
    case ResourceType.Form:
      return `${baseUrl}/form-templatess/${resourceId}`;
    case ResourceType.Invoice:
      return `${baseUrl}/clients/${actorId}/invoices/${resourceId}`;
    case ResourceType.Message:
      return `${orgUrl}/cilents/${actorId}/messaging`;
    case ResourceType.Note:
      return `${baseUrl}/clients/${actorId}/notes/${resourceId}`;
    case ResourceType.Organization:
      return `${baseUrl}/me/${resourceId}`;
    case ResourceType.Package:
      return `${baseUrl}/packages/${resourceId}`;
    case ResourceType.Product:
      return `${baseUrl}/products/${resourceId}`;
    case ResourceType.Scheduler:
      return `${baseUrl}/appointment-types/${resourceId}`;
    case ResourceType.Smartaction:
      // We don't have a screen for this yet
      return undefined;
  }

  // No Link
  return undefined;
}

export function getNotificationMessageParts(notification: NotificationMessage) {
  const { type, resource, action, actor, subject } = notification;

  if (type === NotificationType.Activity && resource && action) {
    return compact([
      resource.preview?.title,
      actionMessage[action],
      actor?.preview?.title,
    ]);
  }

  return [subject];
}
