import { FC } from "react";

import { useGetTier } from "@hooks/use-tier";

import BasicBadgeIcon from "@components/Icons/BasicBadgeIcon";
import ProBadgeIcon from "@components/Icons/ProBadgeIcon";
import TeamsBadgeIcon from "@components/Icons/TeamsBadgeIcon";

interface TierBadgeProps {
  className?: string;
}

const TierBadge: FC<TierBadgeProps> = ({ className }) => {
  const tier = useGetTier();

  switch (tier) {
    case "teams":
    case "business":
      return <TeamsBadgeIcon className={className} />;
    case "pro":
      return <ProBadgeIcon className={className} />;
    default:
      return <BasicBadgeIcon className={className} />;
  }
};

export default TierBadge;
