import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ServicesIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8C2 4.68629 4.68629 2 8 2H16C19.3137 2 22 4.68629 22 8V15H19V16C19 19.3137 16.3137 22 13 22H8C4.68629 22 2 19.3137 2 16V8ZM17 15H4V16C4 18.2091 5.79086 20 8 20H13C15.2091 20 17 18.2091 17 16V15ZM20 13H18L18 8.60977C18 6.8495 17.3194 5.22069 16.1835 4.00413C18.3075 4.10003 20 5.85236 20 8V13ZM12.385 4H11.6838C13.1284 5.30126 14 7.17749 14 9.20255V13H16L16 8.60977C16 6.42 14.503 4.51942 12.385 4ZM7.43337 4.03982C6.98289 4.1037 6.55656 4.24263 6.16744 4.44354C8.12731 5.28474 9.5 7.23206 9.5 9.49999V13H12V9.20255C12 7.10002 10.6847 5.22211 8.70871 4.50359L7.43337 4.03982ZM4.51375 6.03743C4.18664 6.61724 4 7.28682 4 8V13H7.5V9.49999C7.5 7.74152 6.20319 6.28594 4.51375 6.03743Z"
      />
    </svg>
  );
};

export default ServicesIcon;
