import { FC, useCallback } from "react";
import classNames from "classnames";

import useHubspotWidget from "@hooks/use-hubspot-widget";
import pluralHelper from "@lib/utils/pluralHelper";

import ArrowShortcutIcon from "@components/Icons/ArrowShortcutIcon";
import LiveChatIcon from "@components/Icons/LiveChatIcon";

export interface LiveChatSupportBannerInnerProps {
  isAvailable: boolean;
  openChat: () => void;
  hoursUntilChatOpen: number | null;
}

export const LiveChatSupportBannerInner: FC<
  LiveChatSupportBannerInnerProps
> = ({ isAvailable, openChat, hoursUntilChatOpen }) => {
  const title = isAvailable ? (
    "Live chat support available"
  ) : (
    <>
      Chat unavailable —{" "}
      <a href="mailto:support@practice.do" className="underline">
        Send an email
      </a>
    </>
  );
  const subtitle = isAvailable
    ? "Let’s talk about your account"
    : hoursUntilChatOpen
    ? hoursUntilChatOpen > 16
      ? "We’ll be back on Monday"
      : `We’ll be back in ${pluralHelper(hoursUntilChatOpen, "hour", true)}`
    : "Mon-Fri 9am to 5pm EST";

  const onClick = useCallback(() => {
    if (isAvailable) openChat();
  }, [isAvailable, openChat]);

  return (
    <button
      className={classNames(
        "flex items-center w-full gap-4 p-4 rounded-lg",
        isAvailable
          ? "bg-grey-950 hover:bg-grey-900 active:bg-grey-800 cursor-pointer"
          : "border border-dashed border-grey-800"
      )}
      disabled={!isAvailable}
      onClick={onClick}
    >
      <LiveChatIcon
        className={classNames(
          "flex-none",
          isAvailable ? "text-black-ink" : "text-grey-500"
        )}
      />
      <p className="flex flex-col items-start text-sm text-left">
        <span className="font-medium text-black-ink">{title}</span>
        <span className="text-grey-500">{subtitle}</span>
      </p>
      {isAvailable && (
        <ArrowShortcutIcon className="flex-none ml-auto text-grey-500" />
      )}
    </button>
  );
};

const LiveChatSupportBanner: FC = () => {
  const {
    isChatEnabled: isAvailable,
    openChat,
    hoursUntilChatOpen,
  } = useHubspotWidget();

  return (
    <LiveChatSupportBannerInner
      isAvailable={isAvailable}
      openChat={openChat}
      hoursUntilChatOpen={hoursUntilChatOpen}
    />
  );
};

export default LiveChatSupportBanner;
