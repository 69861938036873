import React from "react";

import { SanitizedUserType } from "@lib/shared-types";

import { Button } from "@components/Button";
import LockIcon from "@components/Icons/LockIcon";

import AuthPageShell from "./AuthPageShell";
import CircledIcon from "./CircledIcon";

interface Props {
  coach: SanitizedUserType;
}

export const Unauthorized: React.FC<Props> = ({ coach }) => (
  <AuthPageShell
    metaTitle="Unauthorized"
    metaRobots="noindex"
    coach={coach}
    aboveTitle={<CircledIcon Icon={LockIcon} />}
    title="Thanks for confirming your identity!"
    subtitle="Unfortunately, this page isn't accessible to you!"
    buttons={
      <Button
        variant="primary-theme"
        onClick={() => window.open(`mailto:${coach?.email}`, "_blank")}
      >
        Contact {coach?.firstName}
      </Button>
    }
  />
);
