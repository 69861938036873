import { FC } from "react";
import classNames from "classnames";

import { useIsMobile } from "@hooks/use-window-size";

import { Button } from "@components/Button";
import CheckIcon from "@components/Icons/CheckIcon";
import PathIcon from "@components/Icons/PathIcon";
import PracticeIcon from "@components/Icons/PracticeIcon";
import UnlockIcon from "@components/Icons/UnlockIcon";
import ZapierIcon from "@components/Icons/ZapierIcon";

import { ComparePlanContentProps } from "../types";
import { formatPlanPrice } from "../utils";

const listItems = [
  {
    Icon: UnlockIcon,
    text: "Unlimited contacts & storage",
  },
  {
    Icon: CheckIcon,
    text: "Digital signatures",
  },
  {
    Icon: PathIcon,
    text: "Custom domain & branding",
  },
  {
    Icon: ZapierIcon,
    text: "Zapier integration",
  },
];

const ComparePlanContent: FC<ComparePlanContentProps> = ({
  className,
  plan,
  isLoadingContinueButton,
  withPlanComparison = true,
  onClickContinue,
  onClickCompare,
}) => {
  const isMobile = useIsMobile();

  const { frequency, defaultCoupon } = plan;
  const isLifetimePlan = frequency === "lifetime";
  const fullPrice = formatPlanPrice(plan);
  const discountedPrice = formatPlanPrice(plan, true);
  const priceDetails =
    defaultCoupon && `Billed ${fullPrice} after your first ${frequency}`;

  const renderTestimonialCard = () => (
    <div className="flex-1 self-stretch bg-[url('/images/img_testimonial_cam.jpg')] bg-center bg-cover bg-no-repeat overflow-hidden my-6 rounded-tl-2xl rounded-bl-2xl hidden md:block">
      <div className="flex flex-col gap-2 justify-end w-full h-full p-4 bg-gradient-to-b from-transparent to-black-ink text-white">
        <p className="font-medium">
          &ldquo;Before Practice, it was all over the place. Now, I feel more
          organized which helps me disconnect from work a lot easier.&rdquo;
        </p>
        <div className="w-full h-[2px] bg-grey-250"></div>
        <div className="flex flex-col text-sm">
          <p className="font-medium">Cam Charbonneau</p>
          <p className="text-grey-500">Mental Performance Consultant</p>
        </div>
      </div>
    </div>
  );

  const renderPlanCard = () => (
    <div className="flex flex-col gap-6 w-full md:max-w-[452px] bg-gradient-to-bl from-blue-100 to-blue-300 text-white p-6 rounded-2xl">
      <div className="flex items-center gap-3">
        <PracticeIcon width={24} height={24} />
        <span className="font-medium text-3xl leading-none">Pro</span>
      </div>
      <p>Create a more professional coaching experience for your clients.</p>
      <div className="flex flex-col gap-3">
        {listItems.map(({ Icon, text }, index) => (
          <div key={index} className="flex items-center gap-3">
            <Icon className="flex-none w-6 h-6" />
            <p>{text}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-col gap-1">
        <p className="flex items-center">
          {discountedPrice && (
            <strong className="font-medium text-3xl">{discountedPrice}</strong>
          )}
          {!isLifetimePlan && (
            <sub className="font-normal text-xl">/{frequency.slice(0, 2)}</sub>
          )}
        </p>
        {priceDetails && <p className="text-sm opacity-50">{priceDetails}</p>}
      </div>
      <div
        className={classNames(
          "flex",
          isMobile ? "flex-col gap-2" : "flex-row gap-4"
        )}
      >
        <Button
          variant="primary"
          className="flex-1"
          onClick={onClickContinue}
          isLoading={isLoadingContinueButton}
        >
          Continue
        </Button>
        {withPlanComparison && (
          <Button
            variant="white-blue"
            className="flex-1"
            onClick={onClickCompare}
          >
            Compare plans
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={classNames(
        "flex flex-row items-center w-full md:max-w-[820px]",
        className
      )}
    >
      {renderTestimonialCard()}
      {renderPlanCard()}
    </div>
  );
};

export default ComparePlanContent;
