import { FC, useCallback, useEffect, useState } from "react";
import { SetupIntent } from "@stripe/stripe-js";
import {
  changeSubscriptionPaymentMethod,
  createStripeSubscription,
  ExtendedStripeSubscriptionPlan,
  postStripeSubscriptionInvoicePay,
  postStripeSubscriptionValidatePromoCode,
  updateStripeSubscription,
} from "api-services/definitions/stripe";
import { useApi } from "api-services/endpoints";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import useSnackbar from "@hooks/use-snackbar";
import { SubscriptionCouponType } from "@lib/data/schemas/subscription-plan";
import { getLogger } from "@lib/logger";
import { cleanUpUrl } from "@lib/utils";

import {
  PaymentMethodWrapper,
  PaymentMethodWrapperProps,
} from "@components/PaymentMethod";

import PaymentForm from "./PaymentForm";

interface PaymentFormContainerProps {
  className?: string;
  onIsPromoCodeInvalidChange?: (isPromoCodeInvalid: boolean) => void;
  showCouponField?: boolean;
  onSuccess?: () => void;
  plan?: ExtendedStripeSubscriptionPlan;
  promoCodeId?: string;
  actionButtonTitle: string;
}

const PaymentFormContainer: FC<PaymentFormContainerProps> = ({
  className,
  onIsPromoCodeInvalidChange,
  showCouponField,
  onSuccess,
  plan,
  promoCodeId,
  actionButtonTitle,
}) => {
  const {
    uid,
    subscription,
    hasTrialEnded,
    isAccountPaused,
    hasAccountPaymentMethod,
  } = useAuth();
  const logger = getLogger();
  const snackbar = useSnackbar();
  const [isPromoCodeInvalid, setIsPromoCodeInvalid] = useState<boolean>(false);
  const [updatedCoupon, setUpdatedCoupon] = useState<
    SubscriptionCouponType | undefined
  >(plan?.defaultCoupon);

  const [currentPromoCodeId, setCurrentPromoCodeId] = useState(promoCodeId);
  // @ts-expect-error: the plan prop exists and it is available. Stripe.Subscription type is not correct
  const changePlan = subscription?.plan.id !== plan?.priceId;
  const shouldPayLatestInvoice =
    hasTrialEnded || hasAccountPaymentMethod || isAccountPaused;

  const router = useRouter();

  const { apiCall: changePaymentMethod } = useApi(
    changeSubscriptionPaymentMethod
  );
  const { apiCall: payInvoice } = useApi(postStripeSubscriptionInvoicePay);
  const { apiCall: changeSubscription } = useApi(updateStripeSubscription);
  const { apiCall: createSubscription } = useApi(createStripeSubscription);
  const { apiCall: validatePromoCode, loading: validatePromoCodeLoading } =
    useApi(postStripeSubscriptionValidatePromoCode);

  const handlePayInvoice = useCallback(async () => {
    if (!uid || !subscription?.latest_invoice) return;
    try {
      await payInvoice(
        { userId: uid, invoiceId: subscription?.latest_invoice as string },
        { ...(updatedCoupon && { promoCodeId: currentPromoCodeId }) },
        {}
      );
      snackbar.showMessage("Successfully paid the last invoice");
    } catch (err) {
      logger.error(
        { userId: uid, error: err },
        "Unable to process the last invoice"
      );
      snackbar.showWarning("Unable to process the last invoice");
    }
  }, [
    currentPromoCodeId,
    logger,
    payInvoice,
    snackbar,
    subscription?.latest_invoice,
    uid,
    updatedCoupon,
  ]);

  const createSubscriptionPlan = useCallback(
    async (setupIntent: SetupIntent) => {
      if (!uid) return;
      const res = await createSubscription(
        { userId: uid },
        {
          paymentMethodId: setupIntent.payment_method as string,
          priceId: plan?.priceId as string,
          promoCodeId: currentPromoCodeId,
        },
        {}
      );
      if (!res) {
        logger.error({ userId: uid }, "Subscription start failed");
        snackbar.showWarning("Subscription start failed, please try again");
      } else {
        snackbar.showMessage("Successfully started subscription");
      }
    },
    [
      createSubscription,
      currentPromoCodeId,
      logger,
      plan?.priceId,
      snackbar,
      uid,
    ]
  );

  const updatePaymentMethod = useCallback(
    async (setupIntent: SetupIntent) => {
      if (!uid || !subscription) return;
      const res = await changePaymentMethod(
        { subscriptionId: subscription.id, userId: uid },
        {
          paymentMethodId: setupIntent.payment_method as string,
          ...(currentPromoCodeId && { promoCodeId: currentPromoCodeId }),
        },
        {}
      );
      if (!res) {
        logger.error({ userId: uid }, "Updating card failed");
        snackbar.showWarning("Updating card failed, please try again");
      } else {
        snackbar.showMessage("Successfully updated card");
        if (shouldPayLatestInvoice) {
          await handlePayInvoice();
        }
      }
    },
    [
      changePaymentMethod,
      currentPromoCodeId,
      handlePayInvoice,
      hasTrialEnded,
      logger,
      shouldPayLatestInvoice,
      snackbar,
      subscription,
      uid,
    ]
  );

  const changeSubscriptionPlan = useCallback(
    async (setupIntent: SetupIntent) => {
      if (!plan || !uid || !subscription) return;
      await changeSubscription(
        { userId: uid, subscriptionId: subscription?.id },
        {
          ...(!hasTrialEnded && {
            promoCodeId: currentPromoCodeId,
            trialEnd: "now",
          }),
          paymentMethodId: setupIntent.payment_method as string,
          prorationBehavior: "none",
        },
        { newPriceId: plan.priceId }
      );
      snackbar.showMessage("Successfully changed subscription");
      if (shouldPayLatestInvoice) {
        await handlePayInvoice();
      }
    },
    [
      changeSubscription,
      currentPromoCodeId,
      handlePayInvoice,
      hasTrialEnded,
      plan,
      shouldPayLatestInvoice,
      snackbar,
      subscription,
      uid,
    ]
  );

  // @TODO: check if it needs to use the useCallback hook
  const replaceSubscriptionPlan = async (setupIntent: SetupIntent) => {
    if (!plan || !uid || !subscription) return;
    await createSubscription(
      { userId: uid },
      {
        promoCodeId: currentPromoCodeId,
        paymentMethodId: setupIntent.payment_method as string,
        priceId: plan.priceId,
        cancelCurrentSubscriptionId: subscription?.id,
        trialEnd: "now",
      },
      {}
    );
    snackbar.showMessage("Succesfully created subscription");
  };

  const handleChangeCoupon = async (promotionCode: string) => {
    if (promotionCode.length === 0) {
      setIsPromoCodeInvalid(false);
      updateCoupon(undefined);
      return;
    }

    try {
      // @ts-expect-error @TODO: our generic functions is returning an invalid type, not considering the `data` prop from axios
      const { data } = await validatePromoCode(
        { promoCode: promotionCode },
        {},
        {}
      );

      if (data.valid) {
        updateIsPromoCodeInvalid(false);
        const coupon = data.coupon;
        coupon.promoCode = promotionCode;
        updateCoupon(coupon);
        setCurrentPromoCodeId(data.promoCodeId);
      } else {
        updateIsPromoCodeInvalid(true);
      }
    } catch (err) {
      updateIsPromoCodeInvalid(true);
    }
  };

  const updateIsPromoCodeInvalid = (value: boolean) => {
    setIsPromoCodeInvalid(value);
    onIsPromoCodeInvalidChange && onIsPromoCodeInvalidChange(value);
  };

  const updateCoupon = (coupon: SubscriptionCouponType | undefined) => {
    setUpdatedCoupon(coupon);
  };

  const onValidated: PaymentMethodWrapperProps["onValidated"] = useCallback(
    async (setupIntent) => {
      switch (setupIntent.status) {
        case "succeeded":
          if (!subscription) {
            await createSubscriptionPlan(setupIntent);
          } else if (
            isAccountPaused &&
            !hasAccountPaymentMethod &&
            subscription
          ) {
            await replaceSubscriptionPlan(setupIntent);
          } else if (changePlan) {
            await changeSubscriptionPlan(setupIntent);
          } else {
            await updatePaymentMethod(setupIntent);
          }
          cleanUpUrl();
          router.push({
            pathname: router.pathname,
          });
          onSuccess && onSuccess();
          break;
        default:
          logger.error({ userId: uid }, "Updating card failed");
          snackbar.showWarning("Updating card failed, please try again");
          break;
      }
    },
    [
      changePlan,
      changeSubscriptionPlan,
      createSubscriptionPlan,
      logger,
      onSuccess,
      router,
      snackbar,
      subscription,
      uid,
      updatePaymentMethod,
    ]
  );

  useEffect(() => {
    setUpdatedCoupon(plan?.defaultCoupon);
    setIsPromoCodeInvalid(false);
  }, [plan]);

  return (
    <PaymentMethodWrapper onValidated={onValidated}>
      <PaymentForm
        className={className}
        plan={plan}
        withCouponField={showCouponField ?? !hasTrialEnded}
        coupon={updatedCoupon}
        primaryButtonDisabled={validatePromoCodeLoading || isPromoCodeInvalid}
        onChangeCoupon={handleChangeCoupon}
        actionButtonTitle={actionButtonTitle}
      />
    </PaymentMethodWrapper>
  );
};

export default PaymentFormContainer;
