import React from "react";

interface StickyLayoutContainerProps {
  children: React.ReactNode;
}

const StickyContainerLayout = ({ children }: StickyLayoutContainerProps) => {
  return (
    <div className="flex flex-col bg-white min-h-screen h-screen">
      {children}
    </div>
  );
};

export default StickyContainerLayout;
