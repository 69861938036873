import { FC, useMemo } from "react";
import { getStripeSubscriptionPlans } from "api-services/definitions/stripe";
import { useApiGet } from "api-services/endpoints";
import classNames from "classnames";
import Link from "next/link";

import { useAuth } from "@contexts/auth";
import { useGetTier } from "@hooks/use-tier";
import { getTrialRemainingStatus } from "@lib/utils/subscription-plans";

import AIChatLabelIcon from "@components/Icons/AIChatLabelIcon";
import NotificationPanelTrigger from "@components/NotificationPanel/NotificationPanelTrigger";
import { StripeSubscriptionType } from "@components/Plans/types";
import { formatPlanPrice } from "@components/Plans/utils";

interface TrialButtonProps {
  subscription: StripeSubscriptionType;
  shouldShrink?: boolean;
  onClick: () => void;
}

const TrialButton: FC<TrialButtonProps> = ({
  subscription,
  shouldShrink,
  onClick,
}) => {
  const { data: subscriptionPlans } = useApiGet(
    getStripeSubscriptionPlans,
    {},
    {}
  );

  const trialRemainingStatus = useMemo(
    () => getTrialRemainingStatus(subscription),
    [subscription]
  );

  const currentPlan = subscription?.metadata;
  const plan = useMemo(
    () =>
      currentPlan &&
      subscriptionPlans?.find(
        (plan) =>
          plan.frequency === currentPlan.frequency &&
          plan.tier === currentPlan.tier
      ),
    [currentPlan, subscriptionPlans]
  );

  const discountedPrice = plan && formatPlanPrice(plan, true, true);

  return (
    <div className="w-full" onClick={onClick}>
      <div
        className={classNames(
          "items-center gap-3 bg-black-ink text-grey-900 p-3 rounded-2xl",
          shouldShrink ? "hidden lg:flex" : "flex"
        )}
      >
        <img className="h-6 w-6" src="/favicon-144.png" alt="Practice" />
        <div className="flex flex-col">
          <p className="font-medium">Trial {trialRemainingStatus?.text}</p>
          {discountedPrice && (
            <p className="text-xs text-action-500 underline">
              Get your first month for {discountedPrice}
            </p>
          )}
        </div>
      </div>
      <div
        className={shouldShrink ? "flex justify-center lg:hidden" : "hidden"}
      >
        <img className="h-8 w-8" src="/favicon-144.png" alt="Practice" />
      </div>
    </div>
  );
};

export interface SidebarPracticeButtonProps {
  shouldShrink?: boolean;
  onClickTrialButton: () => void;
  className?: string;
  shouldShowNotifications?: boolean;
  shouldShowAIIcon?: boolean;
}

const SidebarPracticeButton: FC<SidebarPracticeButtonProps> = ({
  shouldShrink = true,
  onClickTrialButton,
  className,
  shouldShowNotifications = true,
  shouldShowAIIcon = false,
}) => {
  const { subscription, organization } = useAuth();
  const isTrial = subscription?.status === "trialing";
  const hasPaymentMethod = !!subscription?.default_payment_method;

  const companyLogo = organization?.showCompanyLogo
    ? organization?.companyLogo?.url
    : undefined;
  const tier = useGetTier();

  const shouldDisplayCompanyLogo = useMemo(() => {
    return companyLogo && tier && ["teams", "business"].includes(tier);
  }, [companyLogo, tier]);

  return (
    <div
      className={classNames(
        "cursor-pointer flex items-center shrink-0 px-6",
        className
      )}
    >
      {isTrial && !hasPaymentMethod ? (
        <TrialButton
          subscription={subscription}
          shouldShrink={shouldShrink}
          onClick={onClickTrialButton}
        />
      ) : (
        <div className="shrink-0 flex-1 flex lg:flex-row sm:flex-col justify-between items-center">
          <Link href="/" legacyBehavior>
            {shouldDisplayCompanyLogo ? (
              <div className="flex flex-col lg:px-2 items-center">
                <div>
                  <img
                    className="h-8 w-8 hidden sm:block lg:hidden"
                    src="/favicon-144.png"
                    alt="Practice"
                  />
                  <img
                    className="max-h-12 block sm:hidden lg:block"
                    src={companyLogo}
                    alt="Practice"
                  />
                </div>
                <div className="flex sm:hidden lg:flex flex-row space-x-1 mt-2">
                  <span className="font-medium text-xs text-gray-500">
                    Powered by
                  </span>
                  <img
                    className="h-4 w-4"
                    src="/favicon-144.png"
                    alt="Practice"
                  />
                  <p
                    className={classNames(
                      "font-medium text-xs text-black-ink",
                      shouldShrink && "hidden lg:block"
                    )}
                  >
                    Practice
                  </p>
                </div>
              </div>
            ) : (
              <div
                className={classNames(
                  "w-full flex items-center",
                  shouldShrink
                    ? "justify-center lg:justify-start lg:px-3"
                    : "px-3"
                )}
              >
                <img
                  className="h-8 w-8"
                  src="/favicon-144.png"
                  alt="Practice"
                />
                <p
                  className={classNames(
                    "ml-2.5 font-normal text-2xl text-black-ink",
                    shouldShrink && "hidden lg:block"
                  )}
                >
                  Practice
                </p>
              </div>
            )}
          </Link>
          {shouldShowNotifications && (
            <div
              className={classNames(
                shouldDisplayCompanyLogo && "mb-5 sm:mb-0 lg:mb-5"
              )}
            >
              <NotificationPanelTrigger
                className="lg:block lg:mt-0 sm:mt-5"
                shouldShrink={shouldShrink}
              />
            </div>
          )}
          {shouldShowAIIcon && (
            <AIChatLabelIcon className="-m-4 hidden lg:block" />
          )}
        </div>
      )}
    </div>
  );
};

export default SidebarPracticeButton;
