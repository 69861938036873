import classNames from "classnames";

import { Button } from "@components/Button";

interface EmailBannerProps {
  icon: React.ComponentType<{ className: string }>;
  header: string;
  buttonTitle: string;
  onClick: () => void;
  isLoading?: boolean;
  fromVerifyEmail?: boolean;
}

const EmailBanner: React.FC<EmailBannerProps> = ({
  icon,
  onClick,
  buttonTitle,
  header,
  isLoading,
  fromVerifyEmail,
}) => {
  const Icon = icon;
  return (
    <div
      className={classNames(
        "flex justify-center items-center px-4",
        fromVerifyEmail ? "bg-blue-950" : "bg-action-950"
      )}
    >
      <Icon className="hidden md:block" />
      <p className="pl-2 pr-6 text-sm sm:text-base">{header}</p>
      <Button
        className={classNames(
          " !py-2 !px-4 my-4 text-white text-sm sm:text-base",
          fromVerifyEmail
            ? "bg-blue-300 hover:bg-blue-500"
            : "bg-action-600 hover:bg-action-500"
        )}
        isLoading={isLoading}
        onClick={() => onClick()}
      >
        {buttonTitle}
      </Button>
    </div>
  );
};

export default EmailBanner;
