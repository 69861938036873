import React, { ComponentType, FC, useState } from "react";
import axios from "axios";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import useSnackbar from "@hooks/use-snackbar";
import { useCheckScreenSize } from "@hooks/use-window-size";
import { compatAuth as auth } from "@lib/firebase-config";

import ClientCheckIcon from "@components/Icons/ClientCheckIcon";
import ClientIcon from "@components/Icons/ClientIcon";

import EmailBanner from "./EmailBanner";

interface VerifyEmailBannerProps {
  clientRedirect?: string;
}

const VerifyEmailBanner: FC<VerifyEmailBannerProps> = ({ clientRedirect }) => {
  const { hasVerifiedEmail, authEmail } = useAuth();
  const snackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const { isMD } = useCheckScreenSize();

  const fromVerifyEmail = router.query?.emailVerified === "true";

  if (hasVerifiedEmail && !fromVerifyEmail) return null;

  const handleSendVerification = async () => {
    setIsLoading(true);
    try {
      await axios.post("/api/v1/send-verification-email", {
        email: authEmail,
        clientRedirect,
      });
      snackbar.showMessage(
        "Verification email sent!",
        "Please check your inbox to complete verification."
      );
    } catch (e) {
      console.log(e);
      snackbar.showWarning("Oops! Something went wrong", e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirect = async () => {
    router.push(clientRedirect || "/");
    await auth.currentUser?.reload();
  };

  if (fromVerifyEmail)
    return (
      <EmailBanner
        icon={ClientCheckIcon as ComponentType<{ className: string }>}
        onClick={handleRedirect}
        header="Account verified, thank you for taking the extra step."
        buttonTitle={isMD ? "Okay, close this" : "Ok"}
        fromVerifyEmail
      />
    );

  return (
    <EmailBanner
      icon={ClientIcon}
      onClick={handleSendVerification}
      header="Check your inbox and spam folder for an account verification email."
      buttonTitle={isMD ? "Resend email" : "Resend"}
      isLoading={isLoading}
    />
  );
};
export default VerifyEmailBanner;
