import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const MailIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7C4 6.44772 4.44772 6 5 6H19C19.5523 6 20 6.44772 20 7V16C20 17.6569 18.6569 19 17 19H7C5.34315 19 4 17.6569 4 16V7ZM6 9.83167V16C6 16.5523 6.44772 17 7 17H17C17.5523 17 18 16.5523 18 16V9.83165L13.6223 12.6459C12.634 13.2812 11.3659 13.2812 10.3777 12.6459L6 9.83167ZM17.1507 8H6.84926L11.4592 10.9636C11.7886 11.1753 12.2113 11.1753 12.5407 10.9636L17.1507 8Z"
      />
    </svg>
  );
};

export default MailIcon;
