import React, { FC, useState } from "react";
import {
  ExtendedStripeSubscriptionPlan,
  getStripeSubscriptionPlans,
} from "api-services/definitions/stripe";
import { useApiGet } from "api-services/endpoints";

import { track } from "@lib/utils/subscription-plans";

import { FeedbackModal } from "@components/Modals";
import BigModal from "@components/Modals/BigModal";
import { PlanPaymentWrapper } from "@components/PaymentMethod";
import SelectPlanContent from "@components/Plans/SelectPlanContent";
import { INTERVAL_OPTIONS } from "@components/Plans/SubscriptionPlanModal";

export interface ComparePlanModalProps {
  show: boolean;
  toggleShow: () => void;
  useBackIcon: boolean;
  onSelectPlan: (plan: ExtendedStripeSubscriptionPlan) => void;
}

const ComparePlanModal: FC<ComparePlanModalProps> = ({
  show,
  toggleShow,
  useBackIcon,
  onSelectPlan,
}) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const { data: subscriptionPlans, loading } = useApiGet(
    getStripeSubscriptionPlans,
    {},
    {}
  );

  const handleChangePlan = (value: ExtendedStripeSubscriptionPlan) => {
    track("coach_subscription_plan_selected", value);
    onSelectPlan(value);
  };

  const renderSupportModal = showFeedbackModal && (
    <FeedbackModal showModal setShowModal={setShowFeedbackModal} supportModal />
  );

  return (
    <PlanPaymentWrapper omitGoFlow={true}>
      <BigModal
        show={show}
        title="A plan for every solopreneur"
        description="Select a new plan and your prefered billing cycle"
        isLoading={loading}
        onActionText="Contact us"
        size="bigger"
        onAction={() => setShowFeedbackModal(true)}
        useBackIcon={useBackIcon}
        toggleShow={toggleShow}
      >
        {!loading && (
          <SelectPlanContent
            cardPrimaryButtonText="Select plan"
            subscriptionPlans={subscriptionPlans || []}
            intervalOptions={INTERVAL_OPTIONS}
            onChange={handleChangePlan}
          />
        )}
        {renderSupportModal}
      </BigModal>
    </PlanPaymentWrapper>
  );
};

export default ComparePlanModal;
