import { FC } from "react";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

import { SVGIconProps } from "@lib/shared-types";

import { NotificationBubble } from "@components/NotificationBubble";

export interface SidebarButtonProps {
  className?: string;
  href?: string;
  onClick?: (value: unknown) => void;
  Icon?: FC<SVGIconProps>;
  label: string;
  extended?: boolean;
  lastItem?: boolean;
  hasUnread?: boolean;
  unreadCount?: number;
}

const SidebarButton: FC<SidebarButtonProps> = ({
  className,
  href,
  onClick,
  Icon,
  label,
  extended,
  lastItem,
  hasUnread,
  unreadCount,
}) => {
  const { pathname } = useRouter();
  const Component = href ? "a" : "button";

  const notificationBubble = hasUnread && (
    <NotificationBubble>{unreadCount}</NotificationBubble>
  );
  const item = (
    <Component
      onClick={onClick}
      className={classNames(
        "relative group flex items-center text-base leading-6 hover:text-black-ink hover:bg-white focus:outline-none focus:text-black-ink focus:bg-white transition ease-in-out duration-150",
        pathname == href ? "text-black-ink bg-white" : "text-grey-500",
        lastItem
          ? "px-2 py-2 font-normal rounded-md"
          : extended
          ? "lg:w-full p-3 my-2 lg:m-0 lg:p-4 font-regular rounded-lg"
          : "px-4 py-4 font-regular rounded-lg",
        className
      )}
      data-heap-event-name={`${label.toLowerCase()}_sidebar_button_clicked`}
    >
      {Icon && <Icon className={classNames(extended ? "lg:mr-4" : "mr-4")} />}
      {extended ? (
        <>
          <div className="hidden lg:flex gap-2 items-center">
            {label}
            {notificationBubble}
          </div>
          <div className="lg:hidden absolute top-0 right-0">
            {notificationBubble}
          </div>
        </>
      ) : (
        <>
          <span className="mr-2">{label}</span>
          {notificationBubble}
        </>
      )}
    </Component>
  );

  return href ? (
    <Link href={href} legacyBehavior>
      {item}
    </Link>
  ) : (
    item
  );
};

export default SidebarButton;
