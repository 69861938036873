import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const NewTabIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.5 5C18.7761 5 19 5.22386 19 5.5L19 14.5C19 14.7761 18.7761 15 18.5 15L17.5 15C17.2239 15 17 14.7761 17 14.5L17 8.41418L7.6967 17.7175C7.50144 17.9127 7.18485 17.9127 6.98959 17.7175L6.28249 17.0104C6.08722 16.8151 6.08722 16.4985 6.28249 16.3033L15.5858 7L9.5 7C9.22386 7 9 6.77614 9 6.5L9 5.5C9 5.22386 9.22386 5 9.5 5L18.5 5Z" />
    </svg>
  );
};

export default NewTabIcon;
