import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const CheckRoundIcon: FC<SVGIconProps> = ({
  className,
  width = 24,
  height = 24,
  fill = "#232229",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("fill-current", className)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="11" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3242 14.2027C10.1289 14.398 9.81234 14.398 9.61708 14.2027L7.06075 11.6464C6.86549 11.4511 6.5489 11.4511 6.35364 11.6464L5.64653 12.3535C5.45127 12.5487 5.45127 12.8653 5.64653 13.0606L9.61707 17.0311C9.81234 17.2264 10.1289 17.2264 10.3242 17.0311L17.8095 9.54585C18.0047 9.35059 18.0047 9.03401 17.8095 8.83875L17.1024 8.13164C16.9071 7.93638 16.5905 7.93638 16.3952 8.13164L10.3242 14.2027Z"
        fill={fill}
      />
    </svg>
  );
};

export default CheckRoundIcon;
