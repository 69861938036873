import { FC, useMemo, useState } from "react";
import { ExtendedStripeSubscriptionPlan } from "api-services/definitions/stripe";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import { useIsMobile } from "@hooks/use-window-size";
import { SubscriptionCouponType } from "@lib/data/schemas/subscription-plan";

import Modal from "@components/Modals/Modal";

import AccountInfo, { AccountInfoStatusType } from "./AccountInfo";
import PaymentFormContainer from "./PaymentFormContainer";

interface PaymentMethodWallProps {
  onBack?: () => void;
  onSuccess?: () => void;
  plan?: ExtendedStripeSubscriptionPlan;
  contentStatus?: AccountInfoStatusType;
  promoCodeId?: string;
  show?: boolean;
}

const PaymentMethodWall: FC<PaymentMethodWallProps> = ({
  onBack,
  onSuccess,
  plan,
  contentStatus,
  promoCodeId,
  show,
}) => {
  const isMobile = useIsMobile();
  const { organization, hasTrialEnded, hasAccountPaymentMethod } = useAuth();
  const [isPromoCodeInvalid, setIsPromoCodeInvalid] = useState<boolean>(false);
  const [updatedCoupon, setUpdatedCoupon] = useState<
    SubscriptionCouponType | undefined
  >(plan?.defaultCoupon);

  const subscriptionStatus = organization?.subscription?.status;

  const modalContentStatus = useMemo(() => {
    if (
      subscriptionStatus === "invalid-payment" &&
      !hasTrialEnded &&
      hasAccountPaymentMethod
    ) {
      return "INVALID_PAYMENT";
    } else if (subscriptionStatus === "paused" && hasAccountPaymentMethod) {
      return "ACCOUNT_PAUSED";
    } else {
      return contentStatus || "ADD_PAYMENT";
    }
  }, [
    contentStatus,
    subscriptionStatus,
    hasTrialEnded,
    hasAccountPaymentMethod,
  ]);

  return (
    <Modal size={isMobile ? "full" : "bigger"} show={show}>
      <div
        className={classNames(
          "flex flex-1 gap-6 p-8 overflow-y-auto",
          isMobile ? "flex-col" : "flex-row"
        )}
      >
        <AccountInfo
          className={classNames("flex-1", isMobile && "mb-auto")}
          status={modalContentStatus}
          paymentFailedDate={(
            organization?.subscription?.paymentFailedDate as any
          )?.toDate()}
          plan={plan}
          onBack={modalContentStatus === "ADD_PAYMENT" ? onBack : undefined}
          coupon={!isPromoCodeInvalid ? updatedCoupon : undefined}
          isPromoCodeInvalid={isPromoCodeInvalid}
        />
        <PaymentFormContainer
          className="flex-1"
          plan={plan}
          promoCodeId={promoCodeId}
          onCouponChange={setUpdatedCoupon}
          onIsPromoCodeInvalidChange={setIsPromoCodeInvalid}
          onSuccess={onSuccess}
          actionButtonTitle="Continue"
          showCouponField={modalContentStatus === "ADD_PAYMENT"}
        />
      </div>
    </Modal>
  );
};

export default PaymentMethodWall;
