import { FC, useMemo, useState } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import { DEFAULT_LIGHT_COLOR, getRGBColor } from "@contexts/theme";
import { useGetTier } from "@hooks/use-tier";
import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";
import { colors as themeColors } from "@lib/utils/theme";

import ClientAvatar from "@components/Client/ClientAvatar";
import NewTabIcon from "@components/Icons/NewTabIcon";
import TierBadge from "@components/Organization/TierBadge";
import { PublicPageColorType } from "@components/PublicProfile/types";
import SidebarProfileMenu from "@components/Sidebar/SidebarProfileMenu";

interface SidebarProfileInnerProps {
  organizationName: string | null;
  profileInformation: string;
  account: AccountType;
  color: PublicPageColorType | undefined | null;
  onClick: () => void;
  onClickUpgrade: (() => void) | undefined;
  shouldShrink: boolean;
  className?: string;
  tier?: "basic" | "pro" | "teams" | "business" | "free";
}

export const SidebarProfileInner: FC<SidebarProfileInnerProps> = ({
  organizationName,
  profileInformation,
  account,
  color,
  onClick,
  onClickUpgrade,
  shouldShrink,
  className,
  tier = "basic",
}) => {
  const renderAvatar = () => (
    <div className="shrink-0 w-8 h-8 rounded-full overflow-hidden bg-grey-900">
      <ClientAvatar client={account} size="smaller" preload={true} />
    </div>
  );

  const isDefaultLightBackground = color
    ? getRGBColor(color.background) ===
      getRGBColor(DEFAULT_LIGHT_COLOR.background)
    : true;

  return (
    <>
      <div
        className={classNames(
          shouldShrink ? "flex lg:hidden" : "hidden",
          "flex-col items-center cursor-pointer",
          className
        )}
        onClick={onClick}
        tabIndex={0}
      >
        {renderAvatar()}
      </div>
      <div
        className={classNames(
          "flex-col group",
          shouldShrink ? "hidden lg:flex" : "flex",
          className
        )}
      >
        <div
          className={classNames(
            "flex gap-2 items-center p-3 rounded-t-2xl cursor-pointer",
            onClickUpgrade ? "hover:opacity-75" : "group-hover:opacity-75"
          )}
          style={{
            backgroundColor: `${
              isDefaultLightBackground
                ? themeColors["grey-800"]
                : color?.background
            }1A`, // 1A = 10% opacity
          }}
          onClick={onClick}
          tabIndex={0}
        >
          {renderAvatar()}
          <div className="flex flex-col truncate">
            <p className="text-sm font-medium truncate">
              {displayNameFromContact(account)}
            </p>
            <p className="text-xs text-grey-500 leading-tight truncate">
              {profileInformation}
            </p>
          </div>
        </div>
        <div
          className={classNames(
            "flex items-center px-4 py-2 text-foreground rounded-b-2xl cursor-pointer",
            onClickUpgrade ? "hover:opacity-75" : "group-hover:opacity-75",
            organizationName ? "justify-center" : "justify-between"
          )}
          style={{
            color: color?.foreground,
            backgroundColor: isDefaultLightBackground
              ? themeColors["grey-900"]
              : color?.background,
          }}
          onClick={onClickUpgrade ?? onClick}
          tabIndex={0}
        >
          {organizationName ? (
            <p className="text-xs truncate">{organizationName}</p>
          ) : (
            <>
              <TierBadge className="h-4 w-auto" />
              {tier !== "business" && (
                <div className="flex gap-2 items-center text-xs">
                  Upgrade plan
                  <NewTabIcon className="w-4 h-4" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

interface SidebarProfileProps {
  shouldShrink?: boolean;
  className?: string;
}

const SidebarProfile: FC<SidebarProfileProps> = ({
  shouldShrink = true,
  className,
}) => {
  const router = useRouter();
  const {
    aid,
    organization,
    organizationAccounts: { accounts },
  } = useAuth();

  const [showMenu, setShowMenu] = useState(false);

  const owner = accounts?.find((account) => account.isOwner);
  const account = accounts?.find((account) => account.id === aid);

  const tier = useGetTier();

  const isTeamsOrBusiness = ["teams", "business"].includes(tier ?? "");

  const organizationName = useMemo(() => {
    if (isTeamsOrBusiness) {
      if (organization?.companyName) return organization.companyName;

      if (owner) return `${owner.firstName}’s team`;
    }

    return null;
  }, [organization?.companyName, owner, tier]);

  const profileInformation = useMemo(() => {
    if (accounts && accounts.length > 1) {
      if (account?.isOwner) {
        return "Owner";
      }

      if (account?.accessType === "full") {
        return "Admin";
      } else if (account?.accessType === "elevated") {
        return "Partner";
      }

      return "Collaborator";
    }

    return account?.email ?? "";
  }, [account?.accessType, account?.email, account?.isOwner, accounts]);

  if (!account) return null;

  const onClickUpgrade = () => router.push("/manage-plan");

  return (
    <div className="relative">
      <SidebarProfileInner
        className={className}
        organizationName={organizationName}
        profileInformation={profileInformation}
        account={account}
        color={organization?.color}
        onClick={() => setShowMenu(true)}
        onClickUpgrade={isTeamsOrBusiness ? undefined : onClickUpgrade}
        shouldShrink={shouldShrink}
        tier={tier}
      />
      <SidebarProfileMenu
        className={classNames(
          "lg:left-auto mx-auto",
          shouldShrink
            ? "left-[20px] bottom-[50px] lg:bottom-[120px]"
            : "bottom-[140px]"
        )}
        show={showMenu}
        setShow={setShowMenu}
      />
    </div>
  );
};

export default SidebarProfile;
