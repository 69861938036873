import { z } from "zod";

import {
  UserOnboardingRequestSchema,
  UserSchema,
  UserUpgradeProRequestSchema,
} from "@lib/data/schemas/user";

import { getSchemaDefinition } from "../common";

const tags = ["user"];

const UserCommonPathSchema = z.object({
  userId: z.string(),
});

export const updateUserOnboardingValues = getSchemaDefinition(
  "/api/v1/users/{userId}/onboarding",
  "patch",
  {
    path: UserCommonPathSchema,
    body: UserOnboardingRequestSchema,
  },
  {
    description: "Endpoint for user to update their onboarding values",
    tags,
  },
  z.object({ id: z.string() }),
  true
);

export const updateUserUpgradeProValues = getSchemaDefinition(
  "/api/v1/users/{userId}/upgrade-pro",
  "patch",
  {
    path: UserCommonPathSchema,
    body: UserUpgradeProRequestSchema,
  },
  {
    description: "Endpoint for user to update their upgrade pro values",
    tags,
  },
  z.object({ id: z.string() }),
  true
);

export const getLookupByDomain = getSchemaDefinition(
  "/api/v1/users/lookup-by-domain",
  "get",
  {
    query: z.object({
      domain: z.string(),
    }),
  },
  {
    description:
      "Returns a User based on their custom domain. This is generally used for routing and custom domain validation.",
    tags: ["user", "custom-domains"],
  },
  z.object({ user: UserSchema })
);

export const getDashboard = getSchemaDefinition(
  "/api/v1/users/{userId}/dashboard",
  "get",
  {
    path: UserCommonPathSchema,
    query: z.object({
      upcoming: z.string().optional(),
    }),
  },
  {
    description: "Get dashboard data",
    tags,
  },
  z.object({
    totalSeconds: z.number(),
    totalAppointments: z.number(),
    totalEvents: z.number(),
  })
);
