import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const UnlockIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="41"
      height="57"
      viewBox="0 0 41 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.05119 24.0896H6.05758V14.0576C6.05758 6.30088 12.3456 0.0128174 20.1024 0.0128174C27.8591 0.0128174 34.1471 6.30088 34.1471 14.0576V15.0608C34.1471 16.723 32.7997 18.0704 31.1375 18.0704C29.4754 18.0704 28.128 16.723 28.128 15.0608V14.0576C28.128 9.62519 24.5348 6.03201 20.1024 6.03201C15.67 6.03201 12.0768 9.62519 12.0768 14.0576V24.0896H36.1535C38.3697 24.0896 40.1663 25.8862 40.1663 28.1024V52.1791C40.1663 54.3954 38.3697 56.1919 36.1535 56.1919H4.05119C1.83498 56.1919 0.0383911 54.3954 0.0383911 52.1791V28.1024C0.0383911 25.8862 1.83498 24.0896 4.05119 24.0896ZM20.1024 46.16C23.4267 46.16 26.1216 43.4651 26.1216 40.1408C26.1216 36.8165 23.4267 34.1216 20.1024 34.1216C16.7781 34.1216 14.0832 36.8165 14.0832 40.1408C14.0832 43.4651 16.7781 46.16 20.1024 46.16Z"
      />
    </svg>
  );
};

export default UnlockIcon;
