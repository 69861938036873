import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { PaymentElement } from "@stripe/react-stripe-js";
import { ExtendedStripeSubscriptionPlan } from "api-services/definitions/stripe";
import classNames from "classnames";

import { useIsMobile } from "@hooks/use-window-size";
import { SubscriptionCouponType } from "@lib/data/schemas/subscription-plan";

import { Button } from "@components/Button";
import { ErrorMessage } from "@components/ErrorMessage";
import CheckRoundIcon from "@components/Icons/CheckRoundIcon";
import CloseIcon from "@components/Icons/CloseIcon";
import { usePaymentMethodConfirmationForm } from "@components/PaymentMethod";
import CouponFormFields, {
  CouponFieldsFormDataType,
} from "@components/PaymentMethod/CouponFormFields";

interface CommonProps {
  plan?: ExtendedStripeSubscriptionPlan;
  primaryButtonDisabled?: boolean;
  coupon?: SubscriptionCouponType;
  withCouponField?: boolean;
  onChangeCoupon?: (value: string) => void;
  actionButtonTitle: string;
}

interface PaymentFormProps extends CommonProps {
  className?: string;
}

const PaymentForm: FC<PaymentFormProps> = ({ className, ...rest }) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={classNames(
        "flex flex-col gap-4 items-stretch justify-center",
        !isMobile && "bg-grey-950 rounded-2xl p-4",
        className
      )}
    >
      <Content {...rest} />
    </div>
  );
};

const Content: FC<CommonProps> = ({
  plan,
  primaryButtonDisabled,
  coupon,
  withCouponField = true,
  onChangeCoupon,
  actionButtonTitle,
}) => {
  const methods = useForm<CouponFieldsFormDataType>({
    defaultValues: {
      hasPromoCode: !!coupon?.promoCode,
      promoCode: coupon?.promoCode,
    },
  });

  useEffect(() => {
    methods.setValue("hasPromoCode", !!coupon?.promoCode);
    methods.setValue("promoCode", coupon?.promoCode);
  }, [coupon]);

  const { errorMessage, handleSubmit, loading } =
    usePaymentMethodConfirmationForm(plan);

  return (
    <form
      className="w-full flex flex-col md:max-w-sm"
      onSubmit={handleSubmit(
        withCouponField ? methods.getValues() : { hasPromoCode: false }
      )}
    >
      <PaymentElement className="block w-full py-2.5 px-4 text-xl border border-grey-900 rounded-lg bg-white" />
      {withCouponField && (
        <>
          {coupon ? (
            <div className="flex items-center space-x-2 bg-green-900 text-black-ink text-sm py-3 px-4 min-h-[50px] rounded-lg mt-4">
              <CheckRoundIcon className="shrink-0 fill-green-600" />
              <p className="grow">{`${coupon.promoCode || ""} applied • ${
                coupon.description || ""
              }`}</p>
              <button onClick={() => onChangeCoupon && onChangeCoupon("")}>
                <CloseIcon />
              </button>
            </div>
          ) : (
            <CouponFormFields
              className="!mt-4"
              methods={methods}
              onChangeCoupon={onChangeCoupon}
            />
          )}
        </>
      )}
      <Button
        type="submit"
        isLoading={loading}
        disabled={primaryButtonDisabled}
        primary
        className="mt-4"
      >
        {actionButtonTitle}
      </Button>
      {errorMessage && (
        <div className="mt-4">
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </div>
      )}
    </form>
  );
};

export default PaymentForm;
