import { FC, Fragment, useRef, useState } from "react";
import compact from "lodash/compact";

import { useAuth } from "@contexts/auth";
import useFeedbackModal from "@hooks/use-feedback-modal";
import useHubspotWidget from "@hooks/use-hubspot-widget";
import { getSupportUrl } from "@lib/utils";

import BookmarkIcon from "@components/Icons/BookmarkIcon";
import HeartOutlineIcon from "@components/Icons/HeartOutlineIcon";
import HubspotIcon from "@components/Icons/HubspotIcon";
import LifeHelpIcon from "@components/Icons/LifeHelpIcon";
import MailIcon from "@components/Icons/MailIcon";
import Menu from "@components/Menu/Menu";
import MenuButton from "@components/Menu/MenuButton";
import { OptionType, useMouseListener } from "@components/Menu/MoreMenu";
import { FeedbackModal } from "@components/Modals";

import SidebarButton from "./SidebarButton";

interface HelpButtonProps {
  extended?: boolean;
}

const HelpButton: FC<HelpButtonProps> = ({ extended = false }) => {
  const { account } = useAuth();
  const accountEmail = account?.email || "";
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [showFeedbackModal, setShowFeedbackModal] = useFeedbackModal();
  const [showSupportModalVersion, setShowSupportModalVersion] = useState(false);
  const { openChat, isChatEnabled } = useHubspotWidget();
  const ref = useRef();

  const handleClick = () => setShowOptions(true);

  const showMoreInfo = (e: MouseEvent) => {
    if (e.button === 2) return;
    if (!ref?.current?.contains(e.target)) {
      setShowOptions(false);
      return;
    }
  };

  const handleClose = (value: boolean) => {
    setShowFeedbackModal(value);
    setShowSupportModalVersion(false);
  };

  useMouseListener(showMoreInfo);

  const options: OptionType[] = compact([
    {
      children: "Knowledge base",
      icon: BookmarkIcon,
      href: "https://help.practice.do/hc/en-us",
      target: "_blank",
      heapEvent: "help-menu-knowledge-base",
    },
    {
      children: "Send feature request",
      icon: HeartOutlineIcon,
      href: getSupportUrl(accountEmail, "feature_request"),
      target: "_blank",
      heapEvent: "help-menu-send-feedback",
    },
    {
      children: "Open support ticket",
      icon: MailIcon,
      href: getSupportUrl(accountEmail, "bug_report"),
      target: "_blank",
      heapEvent: "help-menu-email",
    },
    isChatEnabled && {
      children: "Live chat support",
      icon: HubspotIcon,
      onClick: openChat,
      heapEvent: "help-menu-live-chat-support",
    },
  ]);

  const renderOption = ({ icon: Icon, ...rest }: OptionType, index: number) => (
    <Fragment key={`help-menu-item-${index}`}>
      <MenuButton className="!py-4" icon={<Icon />} {...rest} />
    </Fragment>
  );

  const renderOptions = (
    <Menu
      show={showOptions}
      className="z-50 absolute bottom-12 left-6 mt-8 w-[240px]"
    >
      <div className="z-50 rounded-md bg-white ring-1 ring-black/5 ">
        <div
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {options.map(renderOption)}
        </div>
      </div>
    </Menu>
  );

  return (
    <div className="relative w-full">
      <SidebarButton
        label="Help & support"
        onClick={handleClick}
        Icon={LifeHelpIcon}
        extended={extended}
        className="w-full sm:w-auto sm:m-auto"
      />
      {renderOptions}
      <FeedbackModal
        showModal={showFeedbackModal}
        setShowModal={handleClose}
        supportModal={showSupportModalVersion}
      />
    </div>
  );
};

export default HelpButton;
