import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const LiveChatIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.13572 14.7941L5.69528 16.4378L7.37576 15.9875L8.04081 16.356C8.91596 16.8409 9.92283 17.1176 11 17.1176C11.394 17.1176 11.7793 17.0804 12.1526 17.0092C12.5977 16.9244 13.0554 17.2351 13.2297 17.6533C13.4194 18.1083 13.2656 18.6906 12.785 18.8001C12.211 18.9309 11.6136 19 11 19C9.59561 19 8.27575 18.6381 7.12856 18.0025L4.60786 18.678C3.90802 18.8655 3.26763 18.2251 3.45515 17.5253L4.11247 15.0721C3.40571 13.8793 3 12.487 3 11C3 6.58172 6.58172 3 11 3C13.0998 3 15.0106 3.80898 16.4378 5.13226C16.7801 5.44956 16.465 5.99836 15.9983 5.99811C15.638 5.99792 15.2776 6.02783 14.9233 6.08695C14.7813 6.11065 14.6356 6.07723 14.5179 5.99435C13.5228 5.29375 12.3094 4.88235 11 4.88235C7.62132 4.88235 4.88235 7.62132 4.88235 11C4.88235 12.1402 5.19251 13.2022 5.73191 14.1126L6.13572 14.7941Z" />
      <path d="M14.5149 10.6129C14.1991 10.9879 14 11.5551 14 12.25C14 12.8023 13.5523 13.25 13 13.25C12.4477 13.25 12 12.8023 12 12.25C12 11.1949 12.3009 10.1371 12.9851 9.32461C13.6885 8.48936 14.7264 8 16 8C18.6695 8 20.0967 10.3656 19.7306 12.1961C19.4251 13.7234 18.5407 14.4318 17.9227 14.9268C17.8875 14.955 17.8531 14.9826 17.8197 15.0096C17.5079 15.2615 17.329 15.4266 17.2062 15.6209C17.1 15.7891 17 16.0394 17 16.5C17 17.0523 16.5523 17.5 16 17.5C15.4477 17.5 15 17.0523 15 16.5C15 15.7106 15.1824 15.0799 15.5153 14.5529C15.8315 14.0522 16.2481 13.7082 16.5626 13.454L16.6303 13.3994C17.2258 12.9191 17.608 12.6107 17.7694 11.8039C17.9033 11.1344 17.3305 10 16 10C15.2736 10 14.8115 10.2606 14.5149 10.6129Z" />
      <path d="M15 20C15 19.4477 15.4477 19 16 19C16.5523 19 17 19.4477 17 20C17 20.5523 16.5523 21 16 21C15.4477 21 15 20.5523 15 20Z" />
    </svg>
  );
};

export default LiveChatIcon;
