import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

import MoreIcon from "@components/Icons/MoreIcon";
import Menu from "@components/Menu/Menu";
import MenuButton, { MenuButtonProps } from "@components/Menu/MenuButton";

export interface OptionType extends Omit<MenuButtonProps, "icon"> {
  icon: FC<SVGIconProps>;
  separator?: boolean;
}

interface MoreMenuProps {
  className?: string;
  /**
   * Used to create a uniq key from menu item in case to have multiple
   * instances of the component rendered at the same time
   * */
  name: string;
  iconClassName?: string;
  buttonClassName?: string;
  options: OptionType[];
  menuClassName?: string;
}

export const useMouseListener = (trigger: any) => {
  useEffect(() => {
    document.addEventListener("mousedown", trigger);
    Array.from(document.getElementsByClassName("contentbox")).map((box) =>
      box.addEventListener("contextmenu", (e) => e.preventDefault())
    );

    return () => {
      document.removeEventListener("mousedown", trigger);
      Array.from(document.getElementsByClassName("contentbox")).map((box) =>
        box.removeEventListener("contextmenu", (e) => e.preventDefault())
      );
    };
  }, []);
};

const MoreMenu: FC<MoreMenuProps> = ({
  name,
  className,
  iconClassName,
  buttonClassName,
  menuClassName,
  options,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const showMoreInfo = (e) => {
    if (e.which === 3) return;
    if (!ref?.current?.contains(e.target)) {
      setIsOpen(false);
      return;
    }
  };
  useMouseListener(showMoreInfo);

  const renderMenuItem = (
    { icon: Icon, separator = false, ...rest }: OptionType,
    index: number
  ) => (
    <Fragment key={`more-menu-item-${name}-${index}`}>
      <MenuButton icon={<Icon />} {...rest} />
      {separator && <div className="border-t my-2" />}
    </Fragment>
  );

  return (
    <div className={classNames("relative text-right", className)}>
      <button
        className={classNames(
          "w-8 h-8 inline-flex items-center justify-center text-gray-500 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 Menu ease-in-out duration-150",
          buttonClassName
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <MoreIcon className={iconClassName} />
      </button>

      <Menu
        show={isOpen}
        className={classNames(
          "z-50 absolute top-0 right-0 mt-8 w-[240px] md:w-[180px] lg:w-[240px]",
          menuClassName
        )}
      >
        <div className="z-50 rounded-md bg-white ring-1 ring-black/5 py-2">
          <div
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map(renderMenuItem)}
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default MoreMenu;
