import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const InvoiceIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.192 21.484H13.352V19.108C15.656 18.772 17.216 17.284 17.216 14.956C17.216 12.844 15.8 11.524 13.64 11.02L11.24 10.444C10.016 10.132 9.41599 9.556 9.41599 8.596C9.41599 7.444 10.352 6.652 11.84 6.652C13.496 6.652 14.432 7.492 14.768 8.548H17.096C16.664 6.58 15.416 5.212 13.352 4.78V2.5H11.192V4.66C8.74399 4.9 7.15999 6.556 7.15999 8.764C7.15999 11.044 8.71999 12.148 10.616 12.604L12.968 13.18C14.408 13.516 14.96 14.092 14.96 15.076C14.96 16.372 13.952 17.116 12.32 17.116C10.808 17.116 9.65599 16.468 9.19999 15.028H6.79999C7.20799 17.356 8.86399 18.772 11.192 19.108V21.484Z" />
    </svg>
  );
};

export default InvoiceIcon;
