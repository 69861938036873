import { FC, ReactNode } from "react";
import {
  FeatureFlagKey,
  getFeatureFlagValue,
} from "api-services/definitions/feature-flags";
import { useApiGet } from "api-services/endpoints";
import { useRouter } from "next/router";

import { useOrgContact } from "@hooks/use-contact";

import { Unauthorized } from "@components/Login/Unauthorized";
import { Redirect, ThemedSpinner } from "@components/Redirect";

import { useAuth } from "./auth";

type RestricedAccessProviderProps = {
  children?: ReactNode;
  loginPage?: string;
  query?: Record<string, any>;
  clientAccess?: {
    coach: any;
    clientId: string;
    shared?: boolean;
    broadcasted?: boolean;
  };
  sharedWithPublic?: boolean;
};

export const RestrictedAccessProvider: FC<RestricedAccessProviderProps> = ({
  children,
  loginPage,
  clientAccess,
  sharedWithPublic = false,
}) => {
  const router = useRouter();
  const requestedPage = router.asPath === "/" ? undefined : router.asPath;
  const {
    aid,
    loading: loadingAuth,
    isOwner,
    subscription,
    onboarding,
    isCoach,
    currentWorkspace,
  } = useAuth();

  // This only succeeds if the user is a coach and has access to the contact
  // or if the user is the client themselves
  const { contact: client, isLoading: loadingClient } = useOrgContact(
    clientAccess?.coach.id,
    clientAccess?.clientId
  );

  const { data: featureFlag, isLoading: isLoadingFeatureFlag } = useApiGet(
    onboarding?.completedGoFlowTimeToShine === null
      ? getFeatureFlagValue
      : undefined,
    {},
    { featureFlagKey: FeatureFlagKey.Values.requirePaymentInGoFlow }
  );
  const requirePaymentInGoFlow = featureFlag?.value ?? false;

  // Shortcut for artifacts that are shared publicly (without login)
  if (sharedWithPublic) return <>{children}</>;

  // We're still loading the auth state. Do not render anything.
  if (loadingAuth || loadingClient) return null;

  if (aid && !currentWorkspace?.id) {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  // Visitors that are not signed in will be redirected
  if (!aid) {
    if (loginPage) {
      // The visited page defines a specific login page
      return (
        <Redirect
          coach={clientAccess?.coach}
          to={{
            pathname: `/me/${router.query?.userId}/private`,
            query: {
              requestedPage,
              loginPage,
              contactId: clientAccess?.clientId,
            },
          }}
        />
      );
    }

    return (
      <Redirect
        coach={clientAccess?.coach}
        to={{
          pathname: "/login",
          ...(requestedPage && { query: { requestedPage } }),
        }}
      />
    );
  }

  // A signed in user who's not a coach can't access pages
  // that are not specifically marked for clients (via clientAccess)
  if (!isCoach && !clientAccess) {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  if (clientAccess) {
    const { coach, shared, broadcasted } = clientAccess;

    if (currentWorkspace?.id !== coach.id) {
      // Logged in to the wrong workspace, or not having the access to it
      return (
        <Redirect
          coach={coach}
          to={{
            pathname: "/home",
          }}
        />
      );
    }

    // If the user is a coach but can't access the client
    if (isCoach && !client) {
      return (
        <Redirect
          coach={coach}
          to={{
            pathname: "/home",
          }}
        />
      );
    }

    if (!isCoach) {
      // Artifact is restricted but the coach has not yet shared it,
      if (!shared && !broadcasted) return <Unauthorized coach={coach} />;

      // We'll wait for the client object to be loaded
      // Then we make sure that the client is actually the one who's trying to access the page
      if (router.isFallback || loadingClient)
        return <ThemedSpinner coach={coach} />;

      if (client?.accountId !== aid) return <Unauthorized coach={coach} />;
    }
  }

  const goPath = isOwner ? "/go" : "join-team";
  const choosePlanPath = "/start-trial";
  const timeToShinePath = "/time-to-shine";
  if (isCoach && !clientAccess && !!onboarding) {
    if (isLoadingFeatureFlag) return null;

    // explicit null checks are used below to only redirect if the onboarding key exists AND is null
    if (
      onboarding.completedGoFlowProfileForm === null ||
      (onboarding.completedGoFlowCollectionForm === null && isOwner)
    ) {
      return <Redirect to={`${goPath}?step=2`} />;
    } else if (
      onboarding.completedGoFlowTimeToShine === null &&
      requirePaymentInGoFlow &&
      !subscription &&
      isOwner
    ) {
      return <Redirect to={choosePlanPath} />;
    } else if (
      onboarding.completedGoFlowTimeToShine === null &&
      router.pathname !== timeToShinePath
    ) {
      return <Redirect to={timeToShinePath} />;
    }
  }

  return <>{children}</>;
};
